import React, { useContext, useEffect, useState } from "react";
import "./_midle.scss";
import LinksDropdown from "../Dropdown/selected";
import Goods from "../Goods/goods.jsx";
import Alert from "../alert/_alert.jsx";
import { ModalConfirmation } from "../Modal/_modals";

import { CostomContext } from "../../context.js";
import OtherGoods from "../Goods/otherGoods";

export default function Midle() {
  const {
    tops,
    AlertText,
    show_on,
    isloadWorkshop,
    employee,
    dayAll,
    days,
    monthSelect,
    MONTH_ALL,
    monthString,
    YEARS_ALL,
    years,
    goods,
    addProdGoods,
    addComments,
    products,
    addGoodsCount,
    removeGoodsCount,
    AddGoods,
    Validation,
    months,
    setPOSTs,
    Win_width,
    activeWorkshop,
    isLoadModal,
    otherGood,
  } = useContext(CostomContext);


  useEffect(() => {
    Pos();
  }, [goods]);
  const [goodsProv, setgoodsProv] = useState({});

  const Pos = () => {
    const empl = document.getElementById("employee");
    const day =
      Win_width <= 540
        ? document.getElementById("data_day").value == 0
          ? days
          : +document.getElementById("data_day").value
        : document.getElementById("data_day").value == 0
          ? days
          : document.getElementById("data_day").value;
    const mon =
      document.getElementById("data_month").value == 0
        ? months
        : document.getElementById("data_month").value;
    const dat =
      document.getElementById("data_year").value == 0
        ? years
        : +years + +document.getElementById("data_year").value - 1;
    const timeH =
      new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours();
    const timeM =
      new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes();
    const cg = goods.length;
    let teleg = "";
    let dopGood = '';
    !!otherGood && otherGood.map(el => {
      dopGood += el.name + ' - ' + el.count + (!!el.comment ? ' - ' + el.comment : '') + "    -----\n"
    });
    const suggestion = document.getElementById("suggestions").value;
    goods.map(
      (item, index) =>
      (teleg =
        teleg +
        `\n${item.product} - ${item.quantity} ${item.comment !== "" && item.comment.length > 1 && item.comment
          ? " - " + item.comment
          : ""
        } по ${item.price} ₽ = ${+item.price * +item.quantity} ₽-----`)
    );
    let goodss =
      !!dopGood &&
      `\nДОПОЛНИТЕЛЬНЫЕ ТОВАРЫ:\n${dopGood}\n`;
    let suggest =
      suggestion !== "" &&
      suggestion.length > 1 &&
      `\nКОММЕНТАРИЙ К ЗАКАЗУ:\n${suggestion}\n`;
    let summprice = 0;
    goods.map((item) => {
      let priceItem = +item.price * +item.quantity;
      summprice = +summprice + +priceItem;
    });
    setgoodsProv({ summprice: summprice });
    let summs = `\nПРЕДВАРИТЕЛЬНАЯ СУММА:\n${summprice} ₽\n#${activeWorkshop.replace(
      /[\s.,%]/g,
      "_"
    )}`;
    console.log(dopGood);
    setPOSTs({
      nowDate:
        (days < 10 ? "0" + days : days) +
        "." +
        (months < 10 ? "0" + months : months) +
        "." +
        years +
        " " +
        timeH +
        ":" +
        timeM,
      workshop: activeWorkshop,
      employee: empl.options[empl.selectedIndex].text,
      date: `${day < 10 ? "0" + day : day}.${mon < 10 ? "0" + mon : mon
        }.${dat}`,
      goods: goods,
      dopGoods: dopGood,
      suggestions: suggestion,
      count: cg,
      telegramBot: `\nЗаявка на ${day < 10 ? "0" + day : day}.${mon < 10 ? "0" + mon : mon
        }.${dat} от ${empl.options[empl.selectedIndex].text
        } из ${activeWorkshop}\n`,
      suggest: suggest ? suggest : "",
      summs: summs,
      goodss: goodss ? goodss : "",
    });
  };

  return (
    <>
      {isLoadModal && <ModalConfirmation dataGood={goodsProv} />}
      <Alert tops={tops} Text={AlertText} showon={show_on} />
      <div className="order__content">
        <div className="label--left">Данные заказчика</div>
        <div style={{ marginBottom: "5vw" }}>
          <div className="label--sm">
            Заказывайте заранее. Составляйте план закупок за 2-3 дня и
            заказывайте сразу на несколько дней вперёд. Не гоняйте водителя
            каждый день за очередным пучком зелени или кисточкой для пупка.
            Понятно, что бывают экстремальные ситуации, требующие срочных
            покупок, но не злоупотребляйте этим.
          </div>
          <div className="label--mar">
            Ваш цех:{" "}
            <span style={{ fontWeight: "600", marginLeft: "1rem" }}>
              {activeWorkshop}
            </span>
          </div>
          <div className="label--mar">Ваше имя</div>
          <LinksDropdown
            Pos={Pos}
            id="employee"
            name="employee"
            lables="employes"
            isloadWorkshop={isloadWorkshop}
            key="link2"
            data={employee}
          />
          <small className="label--sm label--mar">ВЫБЕРИТЕ ДАТУ ПОСТАВКИ</small>
          <div key="lin1" className="order__date">
            <LinksDropdown
              Pos={Pos}
              key="link3"
              id="data_day"
              name="data_day"
              data={dayAll}
              now={days}
              // plus="1"
              widths="3rem"
              wids="3rem"
            />
            <LinksDropdown
              Pos={Pos}
              key="link4"
              id="data_month"
              name="data_month"
              monthSelect={monthSelect}
              data={MONTH_ALL}
              now={monthString}
              widths="7rem"
              wids="8.2rem"
            />
            <LinksDropdown
              Pos={Pos}
              key="link5"
              id="data_year"
              name="data_year"
              data={YEARS_ALL}
              now={years}
              widths="5.125rem"
              wids="7.625rem"
            />
          </div>
        </div>
        <div className="label--left">Состав заказа</div>
        <div id="goods1" className="form__order__info">
          <small className="label--sm">ДАННЫЕ О ТОВАРАХ</small>
          <div>
            {goods.length >= 1 &&
              goods.map((item) => (
                <Goods
                  addProdGoods={addProdGoods}
                  addComments={addComments}
                  key={"g" + item.id}
                  products={products}
                  addGoodsCount={addGoodsCount}
                  {...item}
                  removeGoodsCount={removeGoodsCount}
                />
              ))}
          </div>
          <div className="btn btn-secondary" onClick={() => AddGoods()}>
            <span>
              <svg className="icon icon-plus">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 14 14"
                  fontWeight="500"
                  width="14px"
                  height="14px"
                >
                  <path
                    fillRule="evenodd"
                    fill="#6ab04c"
                    d="M13.000,8.000 L8.000,8.000 L8.000,13.000 C8.000,13.552 7.552,14.000 7.000,14.000 C6.448,14.000 6.000,13.552 6.000,13.000 L6.000,8.000 L1.000,8.000 C0.448,8.000 0.000,7.552 0.000,7.000 C0.000,6.448 0.448,6.000 1.000,6.000 L6.000,6.000 L6.000,1.000 C6.000,0.448 6.448,0.000 7.000,0.000 C7.552,0.000 8.000,0.448 8.000,1.000 L8.000,6.000 L13.000,6.000 C13.552,6.000 14.000,6.448 14.000,7.000 C14.000,7.552 13.552,8.000 13.000,8.000 Z"
                  />
                </svg>
              </svg>
            </span>
            <span>Добавить товар</span>
          </div>
        </div>
        <div className="label--left">Дополнительные товары</div>
        <div>
          <div className="label--sm">
            В свободной форме укажите товары и количества не из списка в поле
            ниже
          </div>
          <div>
            <OtherGoods key={'otherGood'} />
          </div>
        </div>
        <div className="label--left">Подтверждение</div>
        <div>
          <div>
            <label>Пожелание / комментарий ко всему заказу:</label>
            <textarea
              onChange={Pos}
              id="suggestions"
              name="suggestions"
              className="textarea"
              style={{
                marginTop: "1rem",
                marginRight: "2rem",
                width: "80%",
                height: "5vw",
              }}
            />
          </div>
          <div>
            <button
              onClick={() => {
                Pos();
                setTimeout(Validation(), 200);
              }}
              style={{ marginBottom: "1rem" }}
              className="btn btn-primary"
            >
              <span>Сформировать заказ</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
