
export const UPLOAD_WORKSHOP = "UPLOAD_WORKSHOP";

const newState = (data_all,goods_all) => {
  const newWorkShop = [...new Set(data_all.map((item) => item.workshop))];
  const newData = newWorkShop.map((items, index) => {
    const countEmp = data_all.filter((item) => items === item.workshop);
    const emp = countEmp.map((item, ind) => {
      if (items === item.workshop) {
        return {
          id: ind + 1,
          data: item.employee,
        };
      }
    });
    const countPr = goods_all.filter((item) => items === item.workshop);
    const prod = countPr.map((item, ind) => {
      if (items === item.workshop) {
        return {
          id: ind + 1,
          data: item.product,
          price: item.price,
        };
      }
    });
    return { id: index + 1, data: items, name: emp, product: prod };
  });
  return newData;
};

export const NewWorkShop = (state, action) => {

    switch (action.type)
    {
        case UPLOAD_WORKSHOP:
            return newState(action.data_all, action.goods_all);
        default:
            return state;
        }
}