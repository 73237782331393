import React, { createContext, useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

export const OrdersContext = createContext();

export const OrdersData = (props) => {
  const [workshopElement, setWorkshopElement] = useState([]);
  const [data, setData] = useState([]);
  const [dataItem, setDataItem] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState(
    useLocation().search.replace("?", "")
  );
  const [totalCount, setTotalCount] = useState(0);

  const loadDataOrderItem = (id) => {
    fetch("https://orders.lukino.ru/api" + id)
      .then((response) => response.json())
      .then((res) => {
        setDataItem(res);
      });
  };

  const OrdersEdit = (id) => {
    fetch("https://orders.lukino.ru/api" + id, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "PUT",
      body: JSON.stringify(dataItem),
    })
      .then((response) => response.json())
      .then((res) => {});
  };

  const filterclick = async () => {
    setCurrentPage(1);
    console.log(location);
    return await setData([]);
  };
  useEffect(() => {
    filterclick().then(() => setFetching(true));
  }, [location]);

  const loadDataOrders = () => {
    fetch(
      "https://orders.lukino.ru/api/admin/orders/find/?page=" +
        currentPage +
        location
    )
      .then((response) => {
        setTotalCount(JSON.parse(response.headers.get("x-total-count")));
        return response.json();
      })
      .then((res) => {
        setData([...data, ...res.data]);
        setWorkshopElement([...new Set(res.workshop)]);
        setCurrentPage((prevState) => prevState + 1);
      })
      .finally(() => setFetching(false));
  };

  const value = {
    loadDataOrders,
    location,
    loadDataOrderItem,
    setFetching,
    fetching,
    data,
    dataItem,
    setData,
    setCurrentPage,
    setDataItem,
    currentPage,
    search,
    setSearch,
    totalCount,
    setTotalCount,
    setLocation,
    workshopElement,
    OrdersEdit,
  };
  return (
    <OrdersContext.Provider value={value}>
      {props.children}
    </OrdersContext.Provider>
  );
};
