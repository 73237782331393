export const UPLOAD_LOGIN = "UPLOAD_LOGIN";

export const Logins = (state, action) => {
  switch (action.type) {
      case UPLOAD_LOGIN:
          console.log(action.data)
      return action.data
    default:
      return state;
  }
};