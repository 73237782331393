import React, { useContext } from "react";
import { OrdersContext } from "../ordersContext";

export default function OrderTable({ setTableActive, tableActive }) {
  const { dataItem, setDataItem } = useContext(OrdersContext);
  return (
    <>
      <div
        className="table--modal-inner"
        onClick={() => setTableActive(!tableActive)}
      ></div>
      <div className="table--modal orders--table">
        <div className="table--modal--wrapper">
          <div className="modal--wrapper">
            <div
              className="table--modal--close"
              onClick={() => setTableActive(!tableActive)}
            >
              Закрыть
            </div>
          </div>
          <div className="table--wrapper">
            <table
              border="1"
              bordercolor="#6ab04c"
              cellpadding="10"
              cellspacing="2"
            >
              <tr>
                <th>Наименование продукта</th>
                <th>Заказано</th>
                <th>Доставлено</th>
                <th>Подтверждено</th>
                <th>Стоимость</th>
                <th>Комментарий</th>
              </tr>
              {dataItem.product.map((el, index) => (
                <tr>
                  <td>
                    <div>
                      <input
                        className="input table--input"
                        type="text"
                        name="productName"
                        value={el.productItem}
                        disabled
                        // onChange={(e) => {
                        //   let prod = dataItem.product;
                        //   let newProd = [];
                        //   for (let i = 0; i < prod.length; i++) {
                        //     if (i === index) {
                        //       newProd.push({
                        //         ...prod[i],
                        //         productItem: e.target.value,
                        //       });
                        //     } else {
                        //       newProd.push(prod[i]);
                        //     }
                        //   }
                        //   setDataItem({
                        //     ...dataItem,
                        //     product: newProd,
                        //   });
                        // }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        className="input table--input"
                        type="number"
                        name="productName"
                        style={{ width: "60px" }}
                        value={el.productItemCount}
                        onChange={(e) => {
                          let prod = dataItem.product;
                          let newProd = [];
                          for (let i = 0; i < prod.length; i++) {
                            if (i === index) {
                              newProd.push({
                                ...prod[i],
                                productItemCount: e.target.value,
                              });
                            } else {
                              newProd.push(prod[i]);
                            }
                          }
                          setDataItem({
                            ...dataItem,
                            product: newProd,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        className="input table--input"
                        type="number"
                        name="productName"
                        style={{ width: "65px" }}
                        value={el.delivery}
                        onChange={(e) => {
                          let prod = dataItem.product;
                          let newProd = [];
                          for (let i = 0; i < prod.length; i++) {
                            if (i === index) {
                              newProd.push({
                                ...prod[i],
                                delivery: +e.target.value,
                              });
                            } else {
                              newProd.push(prod[i]);
                            }
                          }
                          setDataItem({
                            ...dataItem,
                            product: newProd,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        className="input table--input"
                        type="text"
                        name="productName"
                        value={el.supply}
                        style={{ maxWidth: "87px" }}
                        onChange={(e) => {
                          let prod = dataItem.product;
                          let newProd = [];
                          for (let i = 0; i < prod.length; i++) {
                            if (i === index) {
                              newProd.push({
                                ...prod[i],
                                supply: +e.target.value,
                              });
                            } else {
                              newProd.push(prod[i]);
                            }
                          }
                          setDataItem({
                            ...dataItem,
                            product: newProd,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        className="input table--input"
                        type="text"
                        name="productName"
                        value={el.price}
                        style={{ width: "70px" }}
                        onChange={(e) => {
                          let prod = dataItem.product;
                          let newProd = [];
                          for (let i = 0; i < prod.length; i++) {
                            if (i === index) {
                              newProd.push({
                                ...prod[i],
                                price: +e.target.value,
                              });
                            } else {
                              newProd.push(prod[i]);
                            }
                          }
                          setDataItem({
                            ...dataItem,
                            product: newProd,
                          });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <textarea
                        className="textarea table--input"
                        type="text"
                        name="productName"
                        value={el.comment}
                        style={{
                          width: "82px",
                          height: "59px",
                          padding: "1px 11px",
                          display: "block",
                          position: "relative",
                        }}
                        onChange={(e) => {
                          let prod = dataItem.product;
                          let newProd = [];
                          for (let i = 0; i < prod.length; i++) {
                            if (i === index) {
                              newProd.push({
                                ...prod[i],
                                comment: e.target.value,
                              });
                            } else {
                              newProd.push(prod[i]);
                            }
                          }
                          setDataItem({
                            ...dataItem,
                            product: newProd,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
