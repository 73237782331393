import React, { useRef, useEffect, useContext } from "react";
import { SupplyContext } from "../Context/_supplyContext";

export const DeliveryGoods = ({ item }) => {
  const { updateDelivered = Function.prototype, goods } =
    useContext(SupplyContext);

  const countRef = useRef();

  useEffect(() => {
    countRef.current.value = item.delivered;
  }, [item.delivered]);

  // useEffect(() => {
  //   countRef.current.value = item.delivered;
  // }, [goods]);

  return (
    <div className="delivery--goods">
      <span>{item.product}</span>
      <div className="goods--accepted">
        <input
          ref={countRef}
          type="text"
          className="input"
          placeholder="0"
          // value={+item.accepted}
          // max={item.countProduct}
          // min="0"
          onChange={(e) => {
            updateDelivered(item.id, "Fix", +countRef.current.value);
            console.log(goods);
          }}
          onBlur={() => {
            updateDelivered(item.id, "Fix", +countRef.current.value);
            setTimeout((countRef.current.value = item.delivered), 1500);
          }}
        />
        <div style={{ position: "relative", display: "block" }}>
          <div
            onClick={() => updateDelivered(item.id, "Add")}
            className="item__add"
          >
            &#8249;
          </div>
          <div
            className="item__remove"
            onClick={() => updateDelivered(item.id, "Remove")}
          >
            &#8249;
          </div>
        </div>
      </div>
    </div>
  );
};
