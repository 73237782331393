export default function FilterIcon() {
  return (
    <svg
      width="2rem"
      height="2rem"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Filters</title>
      <g
        id="Iconly/Light-Outline/Filter"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Filter" transform="translate(3.000000, 4.000000)" fill="#fff">
          <path
            d="M14.3879,9.708 C16.1039,9.708 17.4999,11.097 17.4999,12.804 C17.4999,14.511 16.1039,15.9 14.3879,15.9 C12.6709,15.9 11.2739,14.511 11.2739,12.804 C11.2739,11.097 12.6709,9.708 14.3879,9.708 Z M14.3879,11.208 C13.4979,11.208 12.7739,11.924 12.7739,12.804 C12.7739,13.685 13.4979,14.4 14.3879,14.4 C15.2769,14.4 15.9999,13.685 15.9999,12.804 C15.9999,11.924 15.2769,11.208 14.3879,11.208 Z M7.0803,12.0928 C7.4943,12.0928 7.8303,12.4288 7.8303,12.8428 C7.8303,13.2568 7.4943,13.5928 7.0803,13.5928 L0.7793,13.5928 C0.3653,13.5928 0.0293,13.2568 0.0293,12.8428 C0.0293,12.4288 0.3653,12.0928 0.7793,12.0928 L7.0803,12.0928 Z M3.113,3.55271368e-15 C4.83,3.55271368e-15 6.226,1.39 6.226,3.097 C6.226,4.804 4.83,6.192 3.113,6.192 C1.397,6.192 0,4.804 0,3.097 C0,1.39 1.397,3.55271368e-15 3.113,3.55271368e-15 Z M3.113,1.5 C2.224,1.5 1.5,2.216 1.5,3.097 C1.5,3.977 2.224,4.692 3.113,4.692 C4.003,4.692 4.726,3.977 4.726,3.097 C4.726,2.216 4.003,1.5 3.113,1.5 Z M16.1911,2.4004 C16.6051,2.4004 16.9411,2.7364 16.9411,3.1504 C16.9411,3.5644 16.6051,3.9004 16.1911,3.9004 L9.8911,3.9004 C9.4771,3.9004 9.1411,3.5644 9.1411,3.1504 C9.1411,2.7364 9.4771,2.4004 9.8911,2.4004 L16.1911,2.4004 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
