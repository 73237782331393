import React, { useContext } from "react";
import { OrdersContext } from "../ordersContext";

export default function DetailProduct(params) {
  const { dataItem, setDataItem } = useContext(OrdersContext);
  const { data, id } = params;
  return (
    <div className="product--detail--wrapper">
      <div className="product--detail--item">
        <span>Продукт:</span>
        <input
          className="input input--productString"
          type="text"
          name="productName"
          value={data.productItem}
          disabled
          // onChange={(e) => {
          //   let prod = dataItem.product;
          //   let newProd = [];
          //   for (let i = 0; i < prod.length; i++) {
          //     if (i === id) {
          //       newProd.push({ ...prod[i], productItem: e.target.value });
          //     } else {
          //       newProd.push(prod[i]);
          //     }
          //   }
          //   setDataItem({
          //     ...dataItem,
          //     product: newProd,
          //   });
          // }}
        />
      </div>
      <div className="product--detail">
        <div className="product--detail--item">
          <span>Заказано:</span>
          <input
            className="input input--productnumber"
            type="number"
            name="productCount"
            value={data.productItemCount}
            onChange={(e) => {
              let prod = dataItem.product;
              let newProd = [];
              for (let i = 0; i < prod.length; i++) {
                if (i === id) {
                  newProd.push({
                    ...prod[i],
                    productItemCount: +e.target.value,
                  });
                } else {
                  newProd.push(prod[i]);
                }
              }
              setDataItem({
                ...dataItem,
                product: newProd,
              });
            }}
          />
        </div>
        <div className="product--detail--item">
          <span>Доставлено:</span>
          <input
            className="input input--productnumber"
            type="number"
            name="diliveryCount"
            value={data.delivery}
            onChange={(e) => {
              let prod = dataItem.product;
              let newProd = [];
              for (let i = 0; i < prod.length; i++) {
                if (i === id) {
                  newProd.push({ ...prod[i], delivery: +e.target.value });
                } else {
                  newProd.push(prod[i]);
                }
              }
              setDataItem({
                ...dataItem,
                product: newProd,
              });
            }}
          />
        </div>
        <div className="product--detail--item">
          <span>Подтверждено:</span>
          <input
            className="input input--productnumber"
            type="number"
            name="supplyCount"
            value={+data.supply}
            onChange={(e) => {
              let prod = dataItem.product;
              let newProd = [];
              for (let i = 0; i < prod.length; i++) {
                if (i === id) {
                  newProd.push({ ...prod[i], supply: +e.target.value });
                } else {
                  newProd.push(prod[i]);
                }
              }
              setDataItem({
                ...dataItem,
                product: newProd,
              });
            }}
          />
        </div>
        <div className="product--detail--item">
          <span>Стоимость:</span>
          <input
            className="input input--productnumber"
            type="number"
            name="Price"
            value={data.price}
            onChange={(e) => {
              let prod = dataItem.product;
              let newProd = [];
              for (let i = 0; i < prod.length; i++) {
                if (i === id) {
                  newProd.push({
                    ...prod[i],
                    price: +e.target.value,
                  });
                } else {
                  newProd.push(prod[i]);
                }
              }
              setDataItem({
                ...dataItem,
                product: newProd,
              });
            }}
          />
        </div>
      </div>
      <div className="product--detail--item ">
        <span>Комментарии:</span>
        <textarea
          className="textarea input--productString"
          name="productName"
          value={data.comment}
          onChange={(e) => {
            let prod = dataItem.product;
            let newProd = [];
            for (let i = 0; i < prod.length; i++) {
              if (i === id) {
                newProd.push({
                  ...prod[i],
                  comment: e.target.value,
                });
              } else {
                newProd.push(prod[i]);
              }
            }
            setDataItem({
              ...dataItem,
              product: newProd,
            });
          }}
        />
      </div>
    </div>
  );
}
