import React, { useEffect, useState } from "react";
import DetailProduct from "./detailProduct.jsx";

function ProductsOrders(props) {
  const { data, id } = props;
  const [active, setActive] = useState(false);
  const [animActive, setAnimActive] = useState(false);

  useEffect(() => {
    if (animActive) {
      setActive(true);
    } else {
      setTimeout(() => setActive(false), 1000);
    }
  }, [animActive]);

  return (
    <>
      <div className="product--item">
        <div>
          {data.productItem}
          <span> &mdash; заказано: </span>
          <span className="product--item--correspond">
            {data.productItemCount}
          </span>
          <span>{` доставлено: `}</span>
          <span
            className={
              +data.productItemCount - +data.delivery === 0
                ? "product--item--correspond"
                : "product--item--notCorrespond"
            }
          >
            {data.delivery}
          </span>
          <span
            onClick={() => setAnimActive(!animActive)}
            className={active ? "item__open" : "item__close"}
          >
            &#8249;
          </span>
        </div>
        {active && (
          <div
            className={
              animActive ? "product--item--open" : "product--item--close"
            }
          >
            <DetailProduct data={data} id={id} />
          </div>
        )}
      </div>
    </>
  );
}
export default React.memo(ProductsOrders);
