import { React, useContext } from "react";
import { AdminContext } from "../../../Context/loginAdminContext.js";
import "./navigation.scss";
import { Link } from "react-router-dom";

export default function NavigationAdmin({ title }) {
  const {} = useContext(AdminContext);
  return (
    <div className={!title ? "navigationadmin" : "navigationadmin--title"}>
      <ul className={"navigationadmin--table"}>
        <Link to="/admin/control_settings/" className="navigationadmin--link">
          <li className="">Настройки управления</li>
        </Link>
        <Link to="/admin/statics/" className="navigationadmin--link">
          <li className="">Статистика</li>
        </Link>
        {/* <Link to="/admin/orders/" className="navigationadmin--link">
          <li className="">Заказы</li>
        </Link> */}
      </ul>
    </div>
  );
}
