import React from "react";
import "./inputdate.scss";

export default function InputDate({
  key,
  TextLabel,
  setDate,
  date,
  dateFrom,
  dateBy,
}) {
  return (
    <>
      <div className="settings--inputElement">
        <label className="label" htmlFor="dateFrom">
          {TextLabel}
        </label>
        <input
          type="date"
          className="input"
          placeholder="01.01.2021"
          name={"dateFrom" + key}
          id={"dateFrom" + key}
          pattern="dd-mm-yyyy"
          required
          min={dateBy && date.dateFrom}
          onChange={(e) => {
            if (e.target.value !== "")
              setDate({
                dateFrom: !!dateFrom ? e.target.value : date.dateFrom,
                dateBy: !!dateBy ? e.target.value : date.dateBy,
              });
          }}
          onBlur={(e) => {
            if (e.target.value !== "")
              setDate({
                dateFrom: !!dateFrom ? e.target.value : date.dateFrom,
                dateBy: !!dateBy ? e.target.value : date.dateBy,
              });
          }}
        />
      </div>
    </>
  );
}
