import React, { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../../../Context/loginAdminContext.js";
import { useLocation } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import InputDate from "./inputDate.jsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const loadDataStatistics = (workshop, setData) => {
  fetch("https://orders.lukino.ru/api/admin/workshop", {
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    method: "POST",
    body: JSON.stringify({
      active: true,
      workshop: workshop,
    }),
  })
    .then((response) => response.json())
    .then((res) => {
      setData(res);
    });
};

export default function WorkshopStatistics() {
  const { BACKGROUNDCOLOR, BORDERCOLOR, loadData } = useContext(AdminContext);
  const location = useLocation();
  const workshop = decodeURIComponent(location.hash).replace("#", "");
  const [data, setData] = useState({});
  const [date, setDate] = useState({
    dateFrom: "01.01.2021",
    dateBy: "01.01.2023",
  });

  const loadTable = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dateFrom = date.dateFrom.split("-");
    const dateBy = date.dateBy.split("-");
    fetch("https://orders.lukino.ru/api/admin/table", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        active: true,
        workshop: workshop,
        dateFrom: date.dateFrom
          ? dateFrom[2] + "." + dateFrom[1] + "." + dateFrom[0]
          : "01.01.2021",
        dateBy: date.dateBy
          ? dateBy[2] + "." + dateBy[1] + "." + dateBy[0]
          : "01.01.2025",
      }),
    })
      .then(function (response) {
        return response.blob();
      })
      .then(function (blob) {
        setTimeout(FileSaver.saveAs(blob, "Excel.xlsx"), 1000);
      });
  };

  useEffect(() => {
    loadDataStatistics(workshop, setData);
  }, [workshop]);
  if (!!data) {
    return (
      <>
        <div className="statistics--nav">
          <div>Выберите цех для подробного анализа:</div>
          <div className="statistics--nav--item">
            <Link to="/admin/statics/" className="nav__item">
              <span className="">Общая</span>
            </Link>
            {loadData.map((el) =>
              el.workshop != workshop ? (
                <Link
                  to={"/admin/workshop/#" + el.workshop}
                  className="nav__item"
                >
                  <span className="">{el.workshop}</span>
                </Link>
              ) : (
                <div className="statistics--nav--label">{el.workshop}</div>
              )
            )}
          </div>
        </div>

        <div className="statistics--workshop">
          <div className="statistics--workshop--label">Цех: {workshop}</div>
          <div className="statistics--bar">
            <div className="statistics--workshop--doughnut">
              <div className="workshop--doughnut">
                <Doughnut
                  className="workshop--doughnut"
                  data={{
                    labels: [
                      "Количество успешно выполненых заказов",
                      "Количество невыполненых заказов",
                    ],
                    datasets: [
                      {
                        label: "Заказы",
                        data: [
                          data.numberOfOrders,
                          data.numberOfUnfulfilledOrders,
                        ],
                        backgroundColor: BACKGROUNDCOLOR,
                        borderColor: BORDERCOLOR,
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
              <div className="workshop--doughnut">
                <Doughnut
                  data={{
                    labels: [
                      "Количество подтвержденных заказов",
                      "Количество неподтвержденных заказов",
                    ],
                    datasets: [
                      {
                        label: "Заказы",
                        data: [
                          data.numberOfConfirmed,
                          data.numberOfUnconfirmed,
                        ],
                        backgroundColor: BACKGROUNDCOLOR,
                        borderColor: BORDERCOLOR,
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <Bar
              data={{
                labels: data.month,
                datasets: [
                  {
                    label: "Доходы за 12 месяцев",
                    data: data.amountOfIncome,
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                ],
              }}
            />
            <Bar
              data={{
                labels: data.month,
                datasets: [
                  {
                    label: "Количество заказанных товаров за 12 месяцев",
                    data: data.goodsCount,
                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                  },
                ],
              }}
            />
          </div>
          <form onSubmit={loadTable}>
            <div className="workshop--form">
              <div className="workshop--inputDate">
                <InputDate
                  key="1"
                  TextLabel="Начальная дата"
                  setDate={setDate}
                  date={date}
                  dateFrom={true}
                  dateBy={false}
                />
                <InputDate
                  key="2"
                  TextLabel="Конечная дата"
                  setDate={setDate}
                  date={date}
                  dateFrom={false}
                  dateBy={true}
                />
              </div>
            </div>
            <div className="workshop--button">
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={() => loadTable(workshop, date)}
              >
                <span>Скачать таблицу</span>
              </button>
            </div>
          </form>
        </div>
      </>
    );
  } else {
    return (
      <div className="statistics--workshop">
        <div className="statistics--workshop--label">Загрузка...</div>
      </div>
    );
  }
}
