import React, { useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LoginOrder } from "./_loginOrder.jsx";
import "./_supplyOrder.scss";
import { SupplyContext } from "../Context/_supplyContext";
import { OrderGoods } from "./_orderGoods.jsx";
import Alert from "../Content/alert/_alert.jsx";

export const OrderSuplly = () => {
  const {
    setCheckingCustomerData,
    dataSupply,
    goods,
    isLoadKey,
    UpDateGCS,
    alertText,
    alertShowOn,
    alertTops,
    FullPurchaseFuncConfirmation,
    setFullPurchaseConfirmation,
    fullPurchaseConfirmation,
  } = useContext(SupplyContext);

  const allCheckRef = useRef();

  let orderNumber = useLocation().pathname.replace("/supply/", "");

  useEffect(() => {
    fetch("https://orders.lukino.ru/api/supply",
    // fetch("http://localhost:4000/api/supply",
      {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({ data: orderNumber }),
    })
      .then((response) => response.json())
      .then((res) => {
        setCheckingCustomerData(res);
        console.log(res);
      });
  }, []);

  return (
    <>
      <Alert
        showon={alertShowOn}
        Text={alertText}
        tops={alertTops ? alertTops : "4rem"}
      />
      {!isLoadKey.key ? (
        <LoginOrder workshop={dataSupply.workshop} keyword="workshop" />
      ) : (
        <div className="supply--wrapper">
          <div className="supply--inner--wrapper">
            <div className="supply--header">Данные заказа</div>
            <div className="supply--body">
              <div>Цех: {dataSupply.workshop}</div>
              <div>Имя заказчика: {dataSupply.employee}</div>
              <div>Дата поставки: {dataSupply.date}</div>
              <div>
                <div key={"prodTitle"} className="supply--goods--title">
                  <span>Наименование товара</span>
                  <span>Доставлено</span>
                  <span>Получено</span>
                </div>
                {goods.map((item, index) => {
                  return <OrderGoods key={"prod" + index} item={item} />;
                })}
              </div>
              <div className="checkbox">
                <div>
                  <input
                    ref={allCheckRef}
                    type="checkbox"
                    name="checkUser"
                    id="delivered"
                    checked={fullPurchaseConfirmation}
                  />
                  <label
                    onClick={() => {
                      setFullPurchaseConfirmation(!fullPurchaseConfirmation);
                      FullPurchaseFuncConfirmation(!fullPurchaseConfirmation);
                    }}
                  >
                    Данные по доставке совпадают с полученым товаром
                  </label>
                </div>
              </div>
            </div>
            <div className="supply--footer">
              <button
                onClick={() => {
                  UpDateGCS("workshop");
                }}
                className="btn btn-primary"
              >
                <span>Подтвердить</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
