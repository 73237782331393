import React, { useState, useContext, useRef } from "react";
import { CostomContext } from "../../context";
import "./productInput.scss";

export default function ProductInput(props) {
  const { data = [], ProdId, addProdGoods = Function.prototype } = props;
  const { setAlertText, setShow_on } = useContext(CostomContext);
  const [productName, setProductName] = useState("");
  const ProductRef = useRef();
  function Validation() {
    let validActive = false;
    data.map((el) => {
      if (el.data === productName.trim()) {
        validActive = true;
      }
    });
    if (validActive) {
      return addProdGoods(ProdId, productName.trim());
    } else {
      setAlertText(
        "Ошибка!! Продукт " +
          productName +
          " не удовлетворяет условиям. Проверьте правильность написания продукта. " +
          "Возможно, присутствуют лишние символы"
      );
      setShow_on(true);
      document
        .getElementsByName(`${ProdId}goods`)[0]
        .scrollIntoView({ block: "center", behavior: "smooth" });
      setTimeout(() => {
        setShow_on(false);
      }, 4500);
    }
  }
  return (
    <>
      <div className="product--input--wrapper">
        <input
          className="input product--input"
          type="text"
          id="goods"
          ref={ProductRef}
          name={ProdId + "goods"}
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          onBlur={(e) => Validation()}
          list={ProdId + "inputProductList"}
          autoComplete="off"
        />

        <datalist id={ProdId + "inputProductList"}>
          <select>
            {data &&
              data
                .filter(
                  (el) =>
                    el.data.toLowerCase().indexOf(productName.toLowerCase()) >=
                    0
                )
                .map((el, index) => (
                  <option key={"as" + index}>{el.data}</option>
                ))}
          </select>
        </datalist>
      </div>
    </>
  );
}
