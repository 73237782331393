import React, { useContext, useState } from "react";
import "./_modal.scss";
import { CostomContext } from "../../context";

export const ModalConfirmation = ({ dataGood }) => {
  const { POSTs, setAlertText, setShow_on, setIsLoadModal } =
    useContext(CostomContext);
  const [load, setLoad] = useState(false);

  console.log(dataGood);
  const loadDataOrder = () => {
    if (!load) {
      setLoad(true);
      fetch( "https://orders.lukino.ru/api/orders",
      //fetch("http://localhost:4000/api/orders",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify(POSTs),
        }
      ).then((response) => {
        // console.log(response.json())
        if (response.status === 200) {
          setAlertText(`Спасибо за ваш заказ! \n Данные уже отправлены!`);
          setShow_on(true);
          setTimeout(() => {
            setShow_on(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }, 2000);
        } else {
          setLoad(false);
          setAlertText(
            `Ошибка: ${response.status}! Форма не отправлена, поробуйте снова`
          );
          setShow_on(true);
          setTimeout(setShow_on(false), 3000);
        }
      });
    }
  };

  return (
    <div className="modal--external--wrapper">
      <div className="modal--inner--wrapper">
        <div className="modal--header">Проверка заказа</div>
        <div className="modal--body">
          <div>Цех: {POSTs.workshop}</div>
          <div>Сотрудник: {POSTs.employee}</div>
          <div>Дата доставки: {POSTs.date}</div>
          <div>
            Товары:{" "}
            {POSTs.goods.map((item) => {
              return (
                <div>
                  {item.product} - {item.quantity}
                  {item.comment !== "" &&
                    item.comment.length > 1 &&
                    item.comment
                    ? " - " + item.comment
                    : ""}{" "}
                  - {item.price} ₽
                </div>
              );
            })}
          </div>
          {POSTs.dopGoods && <div>ДОПОЛНИТЕЛЬНЫЕ ТОВАРЫ:{POSTs.dopGoods.split('-----\n').map(el => (<div>{el}</div>))}</div>}
          {POSTs.suggest && <div>{POSTs.suggest}</div>}
          <div>Предварительная сумма:{dataGood.summprice}</div>
        </div>
        <div className="modal--footer">
          <span
            onClick={() => setIsLoadModal(false)}
            className="btn btn-secondary"
          >
            Изменить заказ
          </span>
          <button
            onClick={() => {
              loadDataOrder();
            }}
            className="btn btn-primary"
          >
            <span>{load ? "Загрузка..." : "Заказать"}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
