import React, { useContext, useState, useRef, useEffect } from "react";
import { CostomContext } from "../../../../context.js";
import { AdminContext } from "../../../Context/loginAdminContext.js";
import { useHistory } from "react-router-dom";
import { OpenIcon, CloseIcon } from "./imgIcon.jsx";
import './authorization.scss'

export const AuthorizationAdmin = ({ panels = false }) => {
  const { setAlertText, setShow_on, setTops } = useContext(CostomContext);
  const { setADMIN_KEYS, ADMIN_KEYS, setAdminInputKey, adminInputKey } =
    useContext(AdminContext);

  const history = useHistory();

  useEffect(() => {
    if (ADMIN_KEYS) history.push("/admin/title");
  }, [ADMIN_KEYS]);

  const maskCheckRef = useRef();
  const inputRef = useRef();

  const adminKeyLoad = () => {
    setAlertText("Проверка ключа...");
    setShow_on(true);
    setTops("0rem");
    fetch("https://orders.lukino.ru/api/admin/key/", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        keys: adminInputKey,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setADMIN_KEYS(res.key);
        if (!res.key) {
          console.log(res.key);
          setAlertText("Ключ указан неверно!");
          setTimeout(() => setShow_on(false), 2500);
        } else {
          setShow_on(false);
          setTops("5rem");
        }
      });
  };
  const LocalstorageLoad = () => {
    if (isLocalstorage) {
      localStorage.setItem("Adminkeys", adminInputKey);
      localStorage.setItem("AdmincheckKey", true);
    } else {
      localStorage.setItem("AdmincheckKey", false);
      localStorage.setItem("Adminkeys", "");
    }
  };
  const [isLocalstorage, setIsLoacalstorage] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  useEffect(() => {
    console.log(localStorage.getItem("AdmincheckKey"));
    setIsLoacalstorage(localStorage.getItem("AdmincheckKey") === "true");
    maskCheckRef.current.checked =
      localStorage.getItem("AdmincheckKey") === "true";
    inputRef.current.value = localStorage.getItem("Adminkeys")
      ? localStorage.getItem("Adminkeys")
      : "";
    setAdminInputKey(
      localStorage.getItem("Adminkeys") ? localStorage.getItem("Adminkeys") : ""
    );
  }, []);
  return (
    <>
      <div className="login--external--wrapper">
        <div className="login--inner--wrapper">
          <div className="login--header">
            <span>Авторизация</span>
          </div>
          <div className="login--body">
            <div className="checkbox">
              <div>
                <input
                  ref={maskCheckRef}
                  type="checkbox"
                  name="authAdmin"
                  id="authAdmin"
                  min="6"
                  max="6"
                  required
                  onChange={(e) => {
                    setIsLoacalstorage(e.target.checked);
                  }}
                />
                <label htmlFor="authAdmin">Запомнить ключ</label>
              </div>
            </div>
            <div className="label--mar">Введите свой персональный код</div>
            <div className="input--password">
              <input
                ref={inputRef}
                maxLength="6"
                className="input"
                type={!openPassword ? "password" : "number"}
                value={adminInputKey}
                onChange={(e) => {
                  setAdminInputKey(e.target.value);
                }}
              />
              <span
                className="input--password--icon"
                onClick={() => setOpenPassword(!openPassword)}
              >
                {!openPassword ? <CloseIcon /> : <OpenIcon />}
              </span>
            </div>
          </div>
          <div className="login--footer">
            <span
              onClick={() => {
                LocalstorageLoad();
                adminKeyLoad();
              }}
              className="btn btn-secondary"
            >
              Войти
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
