import React, { createContext, useState, useEffect, useRef } from "react";

export const SupplyContext = createContext();

export const Context = (props) => {
  //Supply/_order
  const [checkingCustomerData, setCheckingCustomerData] = useState({});
  const [dataSupply, setDataSupply] = useState({});
  const [goods, setGoods] = useState([]);
  const [isLoadKey, setIsLoadKey] = useState("false");
  const [isLoadAdd, setIsLoadAdd] = useState(true);
  //Delivery/_delivery
  const [isLoadDriverKey, setIsLoadDriverKey] = useState("false");
  const [fullPurchase, setFullPurchase] = useState(false);
  const [fullPurchaseConfirmation, setFullPurchaseConfirmation] =
    useState(false);
  const [numberOrders, setNumberOrders] = useState("");

  //Alert
  const [alertText, setAlertText] = useState("");
  const [alertShowOn, setAlertShowOn] = useState(false);
  const [alertTops, setAlertTops] = useState(false);

  //Supply/_loginOrder
  const inputRef = useRef();
  const countRef = useRef();
  const [keys, setKeys] = useState("");

  //Валидация отправки ответа
  const [validation, setValidation] = useState({
    supply: {},
    delivered: {},
    supplyActiv: false,
    deliveredActiv: false,
  });

  // ФОрмирование ответа вслучае невыполнения полной доставки
  useEffect(() => {
    let newsupplyActive = false;
    let newsupplyActiveSurplus = false;
    let newdeliveredActive = false;
    let countProductDelivery = "";
    let countProductSupply = "";
    let countProductSupplySurplus = "";
    let productDelivery = "";
    let productSupply = "";
    let productSupplySurplus = "";
    let priceProductDelivery = "";
    let summDelivery = 0;
    let summSupply = 0;
    let summSupplySurplus = 0;
    let telegrammProductDelivered = "";
    let purchased = "";
    goods.forEach((item) => {
      const newDelivered = +item.countProduct - +item.delivered;
      const newAccepted = +item.delivered - +item.accepted;
      if (newDelivered > 0) {
        productDelivery += `${item.product}; `;
        countProductDelivery += `${newDelivered}; `;
        newdeliveredActive = true;
        priceProductDelivery += `${item.priceProduct}; `;
        summDelivery += +newDelivered * +item.priceProduct;
        // telegrammProductDelivered += `${item.product} - ${newDelivered} по ${
        //   item.priceProduct
        // } ₽ = ${+item.priceProduct * +newDelivered} ₽-----\n`;
        purchased += "0; ";
      }
      if (newAccepted > 0) {
        productSupply += `${item.product} - ${newAccepted} по ${
          item.priceProduct
        } ₽ = ${+item.priceProduct * +newAccepted} ₽-----\n`;
        countProductSupply += `${item.countProduct ? item.countProduct : 0}; `;
        summSupply += +newAccepted * +item.priceProduct;
        newsupplyActive = true;
      }
      if (newAccepted < 0) {
        productSupplySurplus += `${item.product} - ${+newAccepted * -1} по ${
          item.priceProduct
        } ₽ = ${+item.priceProduct * +newAccepted * -1} ₽-----\n`;
        countProductSupplySurplus += `${
          item.countProduct ? item.countProduct : 0
        }; `;
        summSupplySurplus += +newAccepted * +item.priceProduct;
        newsupplyActiveSurplus = true;
      }
      return null;
    });
    const newsupply = {
      product: productSupply + `\nПРЕДВАРИТЕЛЬНАЯ СУММА:\n${summSupply} ₽`,
    };
    const timeH =
      new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours();
    const timeM =
      new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes();
    const dateDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    ).getDate();
    const newdelivered = {
      workshop: dataSupply.workshop,
      employee: dataSupply.employee,
      driver: dataSupply.driver,
      product: productDelivery,
      countProduct: countProductDelivery,
      priceProduct: priceProductDelivery,
      allProduct: countProductDelivery.split("; ").length - 1,
      summ: summDelivery,
      goods: goods,
      telDelivered:
        // telegrammProductDelivered +
        `\nПРЕДВАРИТЕЛЬНАЯ СУММА:\n${summDelivery} ₽`,
      nowDate: `${new Date().getDate()}.${
        new Date().getMonth() + 1
      }.${new Date().getFullYear()} ${timeH}:${timeM}`,
      date: `${dateDay < 10 ? "0" + dateDay : dateDay}.${
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 1
        ).getMonth() + 1
      }.${new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ).getFullYear()}`,
      purchased: purchased,
    };
    setValidation({
      supply: newsupply,
      delivered: newdelivered,
      supplyActiv: newsupplyActive,
      deliveredActiv: newdeliveredActive,
      Surplus: {
        active: newsupplyActiveSurplus,
        product: productSupplySurplus,
      },
    });
  }, [goods]);

  // Проверка ключа на подлинность
  const LoadKey = (duty, keyword) => {
    if (keyword === "workshop") {
      setAlertText("Проверка ключа...");
      setAlertShowOn(true);
      setAlertTops("0rem");
      fetch(
        "https://orders.lukino.ru/api/supply/key/",
        // fetch("http://localhost:4000/api/supply/key/",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify({ workshop: duty, keys: keys }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setIsLoadKey(res);
          if (!res.key) {
            setAlertShowOn(true);
            setAlertText("Ключ указан неверно!");
            setAlertTops("0rem");
          } else {
            setAlertShowOn(false);
            setAlertTops("4rem");
          }
        });
    }
    if (keyword === "driver") {
      setAlertText("Проверка ключа...");
      setAlertShowOn(true);
      setAlertTops("0rem");
      fetch(
        "https://orders.lukino.ru/api/delivery/key/",
        // fetch("http://localhost:4000/api/delivery/key/",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify({ driver: duty, keys: keys }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          setIsLoadDriverKey(res);
          if (!res.key) {
            setAlertShowOn(true);
            setAlertText("Ключ указан неверно!");
            setAlertTops("0rem");
          } else {
            setAlertShowOn(false);
            setAlertTops("4rem");
          }
        });
    }
  };
  // Обновление количества закупленных товаров
  const updateAccepted = (id, action, count, duty) => {
    if (action === "Add") {
      setFullPurchaseConfirmation(false);
      const newGoods = goods.map((el) => {
        if (el.id == id && el.accepted < el.countProduct) {
          return {
            ...el,
            accepted: +el.accepted + 1,
          };
        } else {
          return { ...el };
        }
      });
      setTimeout(setGoods(newGoods), 200);
    }
    if (action === "Remove") {
      setFullPurchaseConfirmation(false);
      const newGoods = goods.map((el) => {
        if (el.id == id) {
          if (el.accepted > 0) {
            return {
              ...el,
              accepted: +el.accepted - 1,
            };
          } else {
            return { ...el };
          }
        } else {
          return { ...el };
        }
      });
      setGoods(newGoods);
    }
    if (action === "Fix") {
      setFullPurchaseConfirmation(false);
      const newGoods = goods.map((el) => {
        if (el.id == id) {
          if (el.accepted < el.countProduct) {
            return {
              ...el,
              accepted: +count,
            };
          } else {
            return {
              ...el,
              accepted: +el.countProduct,
            };
          }
        } else {
          return { ...el };
        }
      });
      setGoods(newGoods);
    }
  };
  // Обновление количества закупленных товаров
  const updateDelivered = (id, action, count) => {
    if (action === "Add") {
      const newGoods = goods.map((el) => {
        if (el.id == id && el.delivered < el.countProduct) {
          setFullPurchase(false);
          return {
            ...el,
            delivered: +el.delivered + 1,
          };
        } else {
          return { ...el };
        }
      });
      setTimeout(setGoods(newGoods), 200);
    }
    if (action === "Remove") {
      const newGoods = goods.map((el) => {
        if (el.id == id) {
          if (el.delivered > 0) {
            setFullPurchase(false);
            return {
              ...el,
              delivered: +el.delivered - 1,
            };
          } else {
            return { ...el };
          }
        } else {
          return { ...el };
        }
      });
      setGoods(newGoods);
    }
    if (action === "Fix") {
      const newGoods = goods.map((el) => {
        if (el.id == id) {
          if (count < el.countProduct) {
            setFullPurchase(false);
            return {
              ...el,
              delivered: +count,
            };
          } else {
            return {
              ...el,
              delivered: +el.countProduct,
            };
          }
        } else {
          return { ...el };
        }
      });
      setGoods(newGoods);
    }
  };

  // Полная закупка
  const FullPurchaseFunc = (activ) => {
    if (activ) {
      const newGoods = goods.map((el) => {
        return {
          ...el,
          delivered: +el.countProduct,
        };
      });
      setTimeout(setGoods(newGoods), 200);
    }
  };
  const FullPurchaseFuncConfirmation = (activ) => {
    if (activ) {
      const newGoods = goods.map((el) => {
        return {
          ...el,
          accepted: +el.delivered,
        };
      });
      setTimeout(setGoods(newGoods), 200);
    }
  };

  // Обновление данных в Гугл таблице
  const UpDateGCS = (type) => {
    let newAccepted = "";
    let newDelivered = "";
    if (type === "workshop" && !!isLoadAdd) {
      setIsLoadAdd(false);
      goods.map((el) => (newAccepted += el.accepted + "; "));
      setAlertShowOn(true);
      setAlertText("Данные по заказу приняты!");
      let Submit = {};
      if (validation.supplyActiv) {
        Submit = {
          data: newAccepted,
          indexRow: dataSupply.index,
          supplyActive: validation.supply,
          workshop: dataSupply.workshop,
          date: dataSupply.date,
          Surplus: validation.Surplus,
        };
      } else {
        Submit = {
          data: newAccepted,
          indexRow: dataSupply.index,
          supplyActive: "",
          workshop: dataSupply.workshop,
          date: dataSupply.date,
          Surplus: validation.Surplus,
        };
      }
      fetch(
        "https://orders.lukino.ru/api/supply/update/",
        // fetch("http://localhost:4000/api/supply/update/",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify(Submit),
        }
      ).then((result) => {
        if (result.ok) {
          setTimeout(() => {
            setAlertShowOn(false);
            setTimeout(window.location.reload(), 500);
          }, 1500);
        }
        if (!result.ok) {
          setIsLoadAdd(true);
          setAlertText("Ошибка при отправке данных, повторите запрос!");
        }
      });
    }
    if (type === "driver" && !!isLoadAdd) {
      setIsLoadAdd(false);
      let Submit = {};
      goods.map((el) => (newDelivered += el.delivered + "; "));
      if (!validation.deliveredActiv) {
        Submit = {
          data: newDelivered,
          indexRow: dataSupply.index,
          workshop: dataSupply.workshop,
          employee: dataSupply.employee,
          date: dataSupply.date,
          orderDate: dataSupply.date,
          driver: isLoadDriverKey.driver ? isLoadDriverKey.driver : "",
          deliveredActive: false,
        };
      } else {
        Submit = {
          data: newDelivered,
          indexRow: dataSupply.index,
          driver: isLoadDriverKey.driver ? isLoadDriverKey.driver : "",
          orders: numberOrders,
          orderDate: dataSupply.date,
          deliveredActive: validation.delivered,
        };
      }
      setAlertShowOn(true);
      setAlertText("Данные по заказу учтены!");
      fetch(
        "https://orders.lukino.ru/api/delivery/update/",
        // fetch("http://localhost:4000/api/delivery/update/",
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify(Submit),
        }
      ).then((result) => {
        if (result.ok) {
          setTimeout(() => {
            setAlertShowOn(false);
            setTimeout(window.location.reload(), 500);
          }, 1500);
        }
        if (!result.ok) {
          setIsLoadAdd(true);
          setAlertText("Ошибка при отправке данных, повторите запрос!");
        }
      });
      //Водитель_Имя не полностью выполнил заказ UID заказа на Дата_Заказа.
      //Не получилось закупить: { Список недопставки в формате утвержденном для заказов }
      // console.log(isLoadDriverKey.driver ? isLoadDriverKey.driver : "");
    }
  };
  // Формируем данные для вывода
  useEffect(() => {
    if (!!checkingCustomerData.workshop) {
      const product = checkingCustomerData.product
        .split(";")
        .filter((el) => el !== "" && el !== " ");
      const delivered = checkingCustomerData.delivered
        .split(";")
        .filter(
          (el) => el !== "" && el !== undefined && el !== null && el !== ";"
        );
      const countProduct = checkingCustomerData.countProduct
        .split(";")
        .filter((el) => el !== "" && el !== " ");
      const accepted = checkingCustomerData.accepted
        .split(";")
        .filter(
          (el) => el !== "" && el !== undefined && el !== null && el !== ";"
        );
      const priceProduct = checkingCustomerData.priceProduct
        .split(";")
        .filter(
          (el) => el !== "" && el !== undefined && el !== null && el !== ";"
        );
      let newGoods = [];
      product.map(
        (item, index) =>
          (newGoods[index] = {
            id: index,
            product: item ? item.trim() : item,
            countProduct: countProduct[index]
              ? countProduct[index].trim()
              : countProduct[index], //Заказано продуктов
            priceProduct: priceProduct[index] ? priceProduct[index] : 0, //Цена продуктов
            delivered: delivered[index] ? delivered[index].trim() : 0, //Доставлено
            accepted: accepted[index] ? accepted[index].trim() : 0, //Принято
          })
      );
      setGoods(newGoods);
      setDataSupply(checkingCustomerData);
    }
  }, [checkingCustomerData]);

  const value = {
    setCheckingCustomerData,
    setIsLoadKey,
    setGoods,
    setDataSupply,
    setKeys,
    UpDateGCS,
    LoadKey,
    updateAccepted,
    setNumberOrders,
    updateDelivered,
    setFullPurchase,
    FullPurchaseFunc,
    FullPurchaseFuncConfirmation,
    setFullPurchaseConfirmation,
    alertTops,
    fullPurchase,
    isLoadKey,
    countRef,
    keys,
    inputRef,
    checkingCustomerData,
    dataSupply,
    goods,
    isLoadDriverKey,
    alertText,
    alertShowOn,
    fullPurchaseConfirmation,
  };
  return (
    <SupplyContext.Provider value={value}>
      {props.children}
    </SupplyContext.Provider>
  );
};
