export const NAME = "NAME";
export const COMMENT = "COMMENT";
export const COUNT = "COUNT";
export const ADD = "ADD";
export const DEL = "DEL";

export const OtherGoodReducer = (state, action) => {
  switch (action.type) {
    case ADD:
      return [...state, { count: 1, name: "", comment: "" }];
    case NAME:
      let newOtherGoodName = Object.create(state);
      newOtherGoodName[action.id] = {
        ...newOtherGoodName[action.id],
        name: action.data,
      };
      return newOtherGoodName;
    case COMMENT:
      let newOtherGoodComment = Object.create(state);
      newOtherGoodComment[action.id] = {
        ...newOtherGoodComment[action.id],
        comment: action.data,
      };
      return newOtherGoodComment;
    case COUNT:
      let newOtherGoodCount = Object.create(state);
      newOtherGoodCount[action.id] = {
        ...newOtherGoodCount[action.id],
        count: action.data,
      };
      return newOtherGoodCount;
    case DEL:
      let newState = Object.create(
        state.filter((el, index) => index != action.id)
      );
      console.log(action.id);
      return newState;
    default:
      return state;
  }
};
