import React from "react";

export default function TableStatistics({
  refButton,
  tableData,
  setActiveWorkshop,
  activeWorkshop,
  thOne,
  thTwo,
  thTree,
}) {
  return (
    <>
      <div
        className="table--modal-inner"
        onClick={() => setActiveWorkshop(!activeWorkshop)}
      ></div>
      <div className="table--modal">
        <div className="table--modal--wrapper">
          <div className="modal--wrapper">
            <div
              className="table--modal--close"
              onClick={() => setActiveWorkshop(!activeWorkshop)}
            >
              Закрыть
            </div>
          </div>
          <div className="table--wrapper">
            <table
              ref={refButton}
              border="1"
              bordercolor="#6ab04c"
              cellpadding="10"
              cellspacing="2"
            >
              <tr>
                <th>{thOne ? thOne : "Название цеха:"}</th>
                <th>{thTwo ? thTwo : "Общая стоимость заказов:"}</th>
                <th>{thTree ? thTree : "Количесвто заказов:"}</th>
              </tr>
              {tableData.map((el) => (
                <tr>
                  <td>{el.workshop}</td>
                  <td>{el.dataSumm}</td>
                  <td>{el.dataCount}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
