import { React, useContext } from "react";
import { AdminContext } from "../../../Context/loginAdminContext.js";
import Header from "../../../../Content/header/header.jsx";
import NavigationAdmin from "../navigation/navigation.jsx";
import { Route } from "react-router-dom";
import ControlSettings from "../settings/controlSettings/controlSettings.jsx";
import "./title.scss";
import Statistics from "../statistics/statistics.jsx";
import WorkshopStatistics from "../statistics/workshop/workshop.jsx";
import { OrdersData } from "../orders/ordersContext.js";
import Orders from "../orders/oreders.jsx";
import OrderManagement from "../orders/orderManagement/orderManagement.jsx";

export default function TitleAdmin() {
  const { activeNavigation, setActiveNavigation } = useContext(AdminContext);
  return (
    <>
      <Header
        adminPanel={true}
        setActiveNavigation={setActiveNavigation}
        activeNavigation={activeNavigation}
      />
      {activeNavigation && <NavigationAdmin />}
      <Route exact path="/admin/control_settings/">
        <ControlSettings />
      </Route>
      <Route exact path="/admin/statics/">
        <Statistics />
      </Route>
      <Route exact path="/admin/">
        <div className="admin--title">
          <div className="admin--title--header">Управление:</div>
          <div>
            <NavigationAdmin title={true} />
          </div>
        </div>
      </Route>
      <Route exact path="/admin/workshop/">
        <WorkshopStatistics />
      </Route>
      <Route exact path="/admin/orders/">
        <OrdersData>
          <Orders />
        </OrdersData>
      </Route>
      <Route exact path="/admin/orders/:id">
        <OrdersData>
          <OrderManagement />
        </OrdersData>
      </Route>
    </>
  );
}
