import React, { useContext } from "react";
import { SupplyContext } from "../Context/_supplyContext";

export const LoginDelivery = ({ keyword }) => {
  const { setKeys, inputRef, LoadKey } = useContext(SupplyContext);

  return (
    <div className="login--external--wrapper">
      <div className="login--inner--wrapper">
        <div className="login--header">
          <span>Авторизация</span>
        </div>
        <div className="login--body">
          <div className="label--mar">Введите свой персональный код</div>
          <div>
            <input
              ref={inputRef}
              className={"input"}
              type="number"
              maxLength="4"
              onChange={(e) => {
                setKeys(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  LoadKey("Водитель", keyword);
                }
              }}
            />
          </div>
        </div>
        <div className="login--footer">
          <span
            onClick={() => LoadKey("Водитель", keyword)}
            className="btn btn-secondary"
          >
            Войти
          </span>
        </div>
      </div>
    </div>
  );
};
