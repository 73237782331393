import React, { useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LoginDelivery } from "./_loginDelivery";
import { SupplyContext } from "../Context/_supplyContext";
import { DeliveryGoods } from "./_deliveryGoods";
import Alert from "../Content/alert/_alert.jsx";

export const OrderDelivery = () => {
  const {
    setCheckingCustomerData,
    dataSupply,
    goods,
    isLoadDriverKey,
    UpDateGCS,
    setNumberOrders,
    setFullPurchase,
    fullPurchase,
    FullPurchaseFunc,
    alertText,
    alertShowOn,
    alertTops,
  } = useContext(SupplyContext);

  const allCheckRef = useRef();

  let orderNumber = useLocation().pathname.replace("/delivery/", "");

  console.log(orderNumber);

  useEffect(() => {
    setNumberOrders(orderNumber);
  }, [orderNumber]);

  useEffect(() => {
    fetch(
      "https://orders.lukino.ru/api/delivery",
      // fetch("http://localhost:4000/api/delivery",
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "POST",
        body: JSON.stringify({ data: orderNumber }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        setCheckingCustomerData(res);
        console.log(res);
      });
  }, []);

  return (
    <>
      <Alert
        showon={alertShowOn}
        Text={alertText}
        tops={alertTops ? alertTops : "4rem"}
      />
      {!isLoadDriverKey.key ? (
        <LoginDelivery keyword="driver" />
      ) : (
        <div className="supply--wrapper">
          <div className="supply--inner--wrapper">
            <div className="supply--header">
              {isLoadDriverKey.driver
                ? "Водитель - " + isLoadDriverKey.driver
                : "Данные заказа"}
            </div>
            <div className="supply--body">
              <div>Цех: {dataSupply.workshop}</div>
              <div>Имя заказчика: {dataSupply.employee}</div>
              <div>Дата поставки: {dataSupply.date}</div>
              <div>
                <div key={"prodTitle"} className="delivery--goods--title">
                  <span>Наименование товара</span>
                  <span>Доставлено</span>
                </div>
                {goods.map((item, index) => {
                  return <DeliveryGoods key={"prod" + index} item={item} />;
                })}
              </div>
              <div className="checkbox">
                <div>
                  <input
                    ref={allCheckRef}
                    type="checkbox"
                    name="checkUser"
                    id="delivered"
                    checked={fullPurchase}
                  />
                  <label
                    onClick={() => {
                      setFullPurchase(!fullPurchase);
                      FullPurchaseFunc(!fullPurchase);
                    }}
                  >
                    Полная закупка
                  </label>
                </div>
              </div>
            </div>
            <div className="supply--footer">
              <button
                onClick={() => {
                  UpDateGCS("driver");
                }}
                className="btn btn-primary"
              >
                <span>Подтвердить</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
