import React, { createContext, useState, useEffect } from "react";

export const AdminContext = createContext();

export const AdminData = (props) => {
  const [ADMIN_KEYS, setADMIN_KEYS] = useState(false);
  const [adminInputKey, setAdminInputKey] = useState(false);
  const [costGoost, setCostGoost] = useState(false);
  const [activeNavigation, setActiveNavigation] = useState(false);
  const [loadData, setLoadData] = useState([]);
  const [dataStatistics, setDataStatistics] = useState([]);
  const BACKGROUNDCOLOR = [
      "rgba(185, 90, 90, 0.5)",
      "rgba(190, 50, 140, 0.5)",
      "rgba(60, 50, 190, 0.5)",
      "rgba(50, 140, 200, 0.5)",
      "rgba(50, 190, 170, 0.5)",
      "rgba(50, 190, 70, 0.5)",
    ],
    BORDERCOLOR = [
      "rgba(186, 93, 93, 1)",
      "rgba(194, 54, 147, 1.2)",
      "rgba(64, 54, 194, 1)",
      "rgba(54, 136, 194, 1)",
      "rgba(54, 194, 171, 1.2)",
      "rgba(54, 194, 75, 1.2)",
    ];

  const loadDataStatistics = () => {
    fetch("https://orders.lukino.ru/api/admin/", {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        active: false,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoadData(res);
      });
  };

  const controlSettings = () => {
    fetch("https://orders.lukino.ru/api/admin/settings")
      .then((response) => response.json())
      .then((res) => {
        setCostGoost(res.active);
      });
  };

  useEffect(() => {
    const newWorkshop = [];
    const newWorkshopCountOrders = [];
    const newWorkshopPriceSumm = [];
    for (let i = 0; i < loadData.length; i++) {
      newWorkshop.push(loadData[i].workshop);
      newWorkshopCountOrders.push(loadData[i].countOrders);
      newWorkshopPriceSumm.push(loadData[i].priceSumm);
    }
    setDataStatistics({
      workshop: newWorkshop,
      countOrders: newWorkshopCountOrders,
      priceSumm: newWorkshopPriceSumm,
    });
  }, [loadData]);

  const value = {
    setADMIN_KEYS,
    setAdminInputKey,
    adminInputKey,
    ADMIN_KEYS,
    setCostGoost,
    costGoost,
    setActiveNavigation,
    activeNavigation,
    loadData,
    loadDataStatistics,
    setLoadData,
    dataStatistics,
    BORDERCOLOR,
    BACKGROUNDCOLOR,
    controlSettings,
  };
  return (
    <AdminContext.Provider value={value}>
      {props.children}
    </AdminContext.Provider>
  );
};
