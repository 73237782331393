import React, { useState, useRef, useEffect, useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./statistics.scss";
import { Link } from "react-router-dom";
import TableStatistics from "./table/table.jsx";
import { AdminContext } from "../../../Context/loginAdminContext";

ChartJS.register(ArcElement, Tooltip, Legend);

function Statistics() {
  const {
    dataStatistics,
    BACKGROUNDCOLOR,
    BORDERCOLOR,
    loadDataStatistics,
    loadData,
  } = useContext(AdminContext);
  const [activeWorkshop, setActiveWorkshop] = useState(false);

  const refButton = useRef();
  const scroll = () => {
    refButton.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };
  useEffect(() => {
    loadDataStatistics();
  }, []);

  useEffect(() => {
    activeWorkshop && scroll();
    activeWorkshop
      ? (window.document.body.style.overflow = "hidden")
      : (window.document.body.style.overflow = "");
  }, [activeWorkshop]);
  const data1 = {
    labels: dataStatistics.workshop,
    datasets: [
      {
        label: "Сумма по заказам",
        data: dataStatistics.priceSumm,
        backgroundColor: BACKGROUNDCOLOR,
        borderColor: BORDERCOLOR,
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: dataStatistics.workshop,
    datasets: [
      {
        label: "Заказы",
        data: dataStatistics.countOrders,
        backgroundColor: BACKGROUNDCOLOR,
        borderColor: BORDERCOLOR,
        borderWidth: 1,
      },
    ],
  };
  let tableData = [];
  for (let i = 0; i < data1.labels.length; i++) {
    tableData.push({
      workshop: data1.labels[i],
      dataSumm: data1.datasets[0].data[i],
      dataCount: data2.datasets[0].data[i],
    });
  }
  return (
    <>
      <div className="statistics--nav">
        <div>Выберите цех для подробного анализа:</div>
        <div className="statistics--nav--item">
          <div className="statistics--nav--label">Общая</div>
          {loadData.map((el) => (
            <Link to={"/admin/workshop/#" + el.workshop} className="nav__item">
              <span className="">{el.workshop}</span>
            </Link>
          ))}
        </div>
      </div>
      <div className="statistics--inner">
        <div className="statistics--element">
          <div className="statistics--lable">
            Представление диаграммы по сумме стоимостей заказов каждым из цехов
            за все периоды:
          </div>
          <div className="statistics--doughnut">
            <Doughnut data={data1} />
          </div>
        </div>
        <div className="statistics--element">
          <div className="statistics--lable">
            Представление диаграммы по количеству заказов каждым из цехов за все
            периоды:
          </div>
          <div className="statistics--doughnut">
            <Doughnut data={data2} />
          </div>
        </div>
      </div>
      <div className="statistics--detail">
        <div>
          <button
            className="btn btn-primary"
            onClick={() => setActiveWorkshop(!activeWorkshop)}
          >
            <span>Представить в виде таблицы</span>
          </button>
        </div>
      </div>
      {activeWorkshop && (
        <TableStatistics
          refButton={refButton}
          tableData={tableData}
          setActiveWorkshop={setActiveWorkshop}
          activeWorkshop={activeWorkshop}
        />
      )}
    </>
  );
}

export default Statistics;
