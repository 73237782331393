import React, { useContext } from "react";
import { CostomContext } from "../../context";
import OtherGoodsItem from "./otherGoodsItem";

export default function OtherGoods() {
    const { otherGood, dispatch_OtherGood }
        = useContext(CostomContext);

    return (<>
        {!!otherGood && otherGood.map((el, index) =>
            <div className="form__order__info" key={index + 'otherGoodItem'} >
                <OtherGoodsItem {...el} id={index} />
            </div>
        )}
        <div className="btn btn-secondary"
            onClick={() => dispatch_OtherGood({ type: 'ADD' })}
        >
            <span>
                <svg className="icon icon-plus">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 14 14"
                        fontWeight="500"
                        width="14px"
                        height="14px"
                    >
                        <path
                            fillRule="evenodd"
                            fill="#6ab04c"
                            d="M13.000,8.000 L8.000,8.000 L8.000,13.000 C8.000,13.552 7.552,14.000 7.000,14.000 C6.448,14.000 6.000,13.552 6.000,13.000 L6.000,8.000 L1.000,8.000 C0.448,8.000 0.000,7.552 0.000,7.000 C0.000,6.448 0.448,6.000 1.000,6.000 L6.000,6.000 L6.000,1.000 C6.000,0.448 6.448,0.000 7.000,0.000 C7.552,0.000 8.000,0.448 8.000,1.000 L8.000,6.000 L13.000,6.000 C13.552,6.000 14.000,6.448 14.000,7.000 C14.000,7.552 13.552,8.000 13.000,8.000 Z"
                        />
                    </svg>
                </svg>
            </span>
            <span>Добавить товар не из списка</span>
        </div>
    </>)
}
