import React, { useContext } from "react";

import { CostomContext } from "../../context";

export default function OtherGoodsItem(props) {
    const { name, id, count, comment } = props;
    const { otherGood, dispatch_OtherGood }
        = useContext(CostomContext);
    return (<>
        <div className="order__info__item">
            <input
                type="text"
                className="input"
                placeholder="Наименование товара"
                value={name}
                onChange={(e) =>
                    dispatch_OtherGood({ type: 'NAME', data: e.target.value, id: id })
                }
            />
            <div className="info--count">
                <div className="order__info__item__count">
                    <input
                        type="text"
                        className="input"
                        placeholder="1"
                        value={count}
                        onChange={(e) => {
                            dispatch_OtherGood({ type: 'COUNT', data: +e.target.value, id: id })
                        }}
                    />
                </div>
                <div style={{ position: "relative", display: "block" }}>
                    <div onClick={() => {
                        const goodCount = +count + 1;
                        dispatch_OtherGood({ type: 'COUNT', data: goodCount, id: id })
                    }}
                        className="item__add">
                        &#8249;
                    </div>
                    <div
                        className="item__remove"
                        onClick={() => {
                            if (!!count && +count > 1) {
                                console.log(count)
                                const goodCount = count - 1;
                                dispatch_OtherGood({ type: 'COUNT', data: goodCount, id: id })
                            } else {
                                dispatch_OtherGood({ type: 'DEL', id: id });
                            }
                        }}
                    >
                        &#8249;
                    </div>
                </div>
            </div>
        </div>
        <div className="order__info_comment">
            <label className="label" htmlFor="inputFromGood">
                Комментарий к товару
            </label>
            <div>
                <input
                    type="text"
                    className="input"
                    value={comment}
                    placeholder="..."
                    onChange={(e) => {
                        dispatch_OtherGood({ type: 'COMMENT', data: e.target.value, id: id })
                    }}
                />
            </div>
        </div>
    </>)
}
