import React, { useContext, useState, useRef, useEffect } from "react";
import "./_login.scss";
import { CostomContext } from "../context.js";
import LinksDropdown from "../Content/Dropdown/selected";
import Alert from "../Content/alert/_alert.jsx"

export const Login = ({ log }) => {
  const {
    selectRefText,
    employeeName,
    setselectRefText,
    workshop,
    dispatch_ActiveWorkshop,
    UPLOAD_LOGIN,
    setInputKey,
    setKEYS,
    inputKey,
    activeWorkshop,
    data_all,
    setAlertText,
    setShow_on,
    setTops,
    tops,
    show_on,
    AlertText
  } = useContext(CostomContext);

  const maskCheckRef = useRef();
  const inputRef = useRef();

  const keyLoad = () => {
    let newIndexKey = "";
    data_all.map((el) => {
      if (el.workshop === activeWorkshop) {
        newIndexKey = el.indexKey;
      }
    });
    setAlertText("Проверка ключа...");
    setShow_on(true);
    setTops("0rem");
    fetch("https://orders.lukino.ru/api/workshop/key/",
    // fetch("http://localhost:4000/api/workshop/key/",
      {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "POST",
      body: JSON.stringify({
        workshop: activeWorkshop,
        keys: inputKey,
        indexKey: newIndexKey,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setKEYS(res.key);
        if (!res.key) {
          console.log(res.key);
          setAlertText("Ключ указан неверно!");
          setTimeout(()=> setShow_on(false), 2500);
        } else {
          setShow_on(false);
          setTops("5rem");
        }
      });
  };
  const LocalstorageLoad = () => {
    if (isLocalstorage) {
      localStorage.setItem("keys", inputKey);
      localStorage.setItem("checkKey", true);
    } else {
      localStorage.setItem("checkKey", false);
      localStorage.setItem("keys", "");
    }
  };
  useEffect(() => {
    console.log(localStorage.getItem("checkKey"));
    setIsLoacalstorage(localStorage.getItem("checkKey") === "true");
    maskCheckRef.current.checked = localStorage.getItem("checkKey") === "true";
    inputRef.current.value = localStorage.getItem("keys")
      ? localStorage.getItem("keys")
      : "";
    setInputKey(
      localStorage.getItem("keys") ? localStorage.getItem("keys") : ""
    );
  }, []);
  const [isLocalstorage, setIsLoacalstorage] = useState(false);
  return (
    <>
      <Alert showon={show_on} Text={AlertText} tops={tops} />
      <div className="login--external--wrapper">
        <div className="login--inner--wrapper">
          <div className="login--header">
            <span>Авторизация</span>
          </div>
          <div className="login--body">
            <div className="label--mar">Выберите Ваш цех</div>
            <LinksDropdown
              dispatch_ActiveWorkshop={dispatch_ActiveWorkshop}
              UPLOAD_LOGIN={UPLOAD_LOGIN}
              selectRefText={selectRefText}
              employeeName={employeeName}
              setselectRefText={setselectRefText}
              id="workshop"
              name="workshop"
              key="link1"
              data={workshop}
            />
            <div className="checkbox">
              <div>
                <input
                  ref={maskCheckRef}
                  type="checkbox"
                  name="checkUser"
                  id="maskCheck"
                  onChange={(e) => {
                    setIsLoacalstorage(e.target.checked);
                  }}
                />
                <label htmlFor="maskCheck">Запомнить ключ</label>
              </div>
            </div>
            <div className="label--mar">Введите свой персональный код</div>
            <div>
              <input
                ref={inputRef}
                maxLength="4"
                className="input"
                type="number"
                onChange={(e) => {
                  setInputKey(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="login--footer">
            <span
              onClick={() => {
                setTimeout(() => {
                  LocalstorageLoad();
                  keyLoad();
                }, 1000);
              }}
              className="btn btn-secondary"
            >
              Войти
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
