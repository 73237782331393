import React, { createContext, useState, useEffect, useReducer } from "react";
import { UPLOAD_WORKSHOP, NewWorkShop } from "./Content/redusers/_workshop";
import { UPLOAD_LOGIN, Logins } from "./Content/redusers/_login";
import {
  NAME,
  COMMENT,
  COUNT,
  OtherGoodReducer,
} from "./Content/redusers/_othergood";
export const CostomContext = createContext();

export const Context = (props) => {
  const toDay = new Date();
  const days = toDay.getDate();
  const months = toDay.getMonth() + 1;
  const years = toDay.getFullYear();
  const [monthString, setMonthString] = useState();
  const [maxday, setMaxday] = useState(
    32 - new Date(years, months - 1, 32).getDate()
  );
  const [dayAll, setDayAll] = useState([]);
  let count = [];
  const [data_all, setData_all] = useState([]);
  const [goods_all, setGoods_all] = useState([]);
  const [goods, setGoods] = useState([
    { id: 0, quantity: 1, product: "", comment: "", price: "" },
  ]);
  const [otherGood, dispatch_OtherGood] = useReducer(OtherGoodReducer, []);
  const [workshop, dispatch_Workshop] = useReducer(NewWorkShop, []);
  const [activeWorkshop, dispatch_ActiveWorkshop] = useReducer(Logins, "");
  const [inputKey, setInputKey] = useState("");
  const [KEYS, setKEYS] = useState(false); //Изменить на фолс

  const [employee, setEmployee] = useState([]);
  const [products, setProducts] = useState([
    // { id: 0, data: "Курица" },
    // { id: 1, data: "Мясо" },
  ]);
  const [isloadWorkshop, setisloadWorkshop] = useState(true);
  //Загрузка модального окна
  const [isLoadModal, setIsLoadModal] = useState(false);
  const [isloadData, setisloadData] = useState(true);
  const [POSTs, setPOSTs] = useState({});
  const [show_on, setShow_on] = useState(false);
  const [Win_width, setWin_width] = useState(
    document.documentElement.clientWidth
  );
  const [AlertText, setAlertText] = useState("");
  const [tops, setTops] = useState("5rem");
  const [selectRefText, setselectRefText] = useState(); // Меняет текст у рефов при смене цеха
  const MONTH_ALL = [
    { id: 1, data: "Январь" },
    { id: 2, data: "Февраль" },
    { id: 3, data: "Март" },
    { id: 4, data: "Апрель" },
    { id: 5, data: "Май" },
    { id: 6, data: "Июнь" },
    { id: 7, data: "Июль" },
    { id: 8, data: "Август" },
    { id: 9, data: "Сентябрь" },
    { id: 10, data: "Октябрь" },
    { id: 11, data: "Ноябрь" },
    { id: 12, data: "Декабрь" },
  ];
  const YEARS_ALL = [
    { id: 1, data: years },
    { id: 2, data: years + 1 },
    { id: 3, data: years + 2 },
  ];

  const monthSelect = (dat) => {
    let mon = new Date(years, dat - 1, 32).getDate();
    setMaxday(32 - mon);
  };
  window.addEventListener("resize", () =>
    setWin_width(document.documentElement.clientWidth)
  );

  useEffect(() => {
    if (Win_width <= 768) {
      setTops("4.5rem");
      if (Win_width <= 540) {
        setTops("4rem");
      }
    } else {
      setTops("5rem");
    }
  }, [Win_width]);
  //при изменении goods перезаписывать функции отправки

  useEffect(() => {
    //Загрузка данных цеха и сотрудников
    if (isloadData) {
      //fetch("http://localhost:4000/api/workshop/")
         fetch("https://orders.lukino.ru/api/workshop/")
        .then((response) => response.json())
        .then((res) => {
          setData_all(res);
        })
        //Загрузка данных Цеха и товаров
        .then(() =>
            fetch("https://orders.lukino.ru/api/goods/")
        //  fetch("http://localhost:4000/api/goods/")
            .then((response) => response.json())
            .then((res) => {
              setGoods_all(res);
            })
            .then(() => setisloadData(false))
        );
    }
  }, [goods_all, isloadData]);

  useEffect(() => {
    dispatch_Workshop({
      type: UPLOAD_WORKSHOP,
      data_all: data_all,
      goods_all: goods_all,
    });
  }, [data_all, goods_all]);

  const employeeName = (workshop_id, sbros) => {
    if (sbros) {
      document
        .querySelectorAll("#employes")
        .forEach((item) => (item.textContent = "Выбрать"));
    }
    workshop.map((item) => {
      if (item.id == workshop_id) {
        setselectRefText(!selectRefText);
        setEmployee(item.name);
        setProducts(item.product);
        setisloadWorkshop(false);
      }
      if (workshop_id == 0) {
        setProducts();
        setEmployee();
        setisloadWorkshop(true);
        setselectRefText(!selectRefText);
        document
          .querySelectorAll("#employes")
          .forEach((item) => (item.textContent = "Выбрать"));
      }
      return null;
    });
  };

  useEffect(() => {
    setMonthString(() => {
      for (let i = 0; i < MONTH_ALL.length; i++) {
        if (MONTH_ALL[i].id === months) {
          return MONTH_ALL[i].data;
        }
      }
    });
  }, [months, years]);

  useEffect(() => {
    for (let i = 0; i <= maxday; i++) {
      count[i] = { id: i, data: i + 1, type: "day" };
    }
    setDayAll(count);
  }, [maxday]);

  useEffect(() => {
    if (isLoadModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isLoadModal]);

  const addGoodsCount = (id, q) => {
    const newGoodsItem = goods.map((item) => {
      if (item.id === id) {
        if (q) {
          return {
            ...item,
            quantity: q,
          };
        } else {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
      } else {
        return item;
      }
    });
    setGoods(newGoodsItem);
  };

  const addComments = (id, data) => {
    const newGoodsItem = goods.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          comment: data,
        };
      } else {
        return item;
      }
    });
    setGoods(newGoodsItem);
  };
  async function addProdGoods(id, data) {
    let pric = 0;
    products &&
      products.map((item) => {
        if (item.data === data) {
          pric = +item.price;
        }
      });
    const newGoodsItem = goods.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          product: data,
          price: pric ? pric : 0,
        };
      } else {
        return item;
      }
    });
    setGoods(newGoodsItem);
  }

  const removeGoodsCount = (id, q) => {
    if (q > 1) {
      const newGoodsItem = goods.map((item) => {
        if (item.id === id && item.quantity > 1) {
          if (item.quantity < 2) {
            return {
              ...item,
              quantity: 1,
            };
          } else {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }
        } else {
          return item;
        }
      });
      setGoods(newGoodsItem);
    }
    if (q === 1) {
      delCart(id);
    }
  };

  const delCart = (id) => {
    if (goods.length > 1) {
      const newGoods = goods.filter((el) => el.id !== id);
      setGoods(newGoods);
    }
  };
  async function AddGoods() {
    let newGoods = [];
    for (let i = 0; i < goods.length; i++) {
      newGoods[i] = {
        id: goods[i].id,
        quantity: goods[i].quantity,
        comment: goods[i].comment,
        product: goods[i].product,
        price: goods[i].price ? goods[i].price : 0,
      };
    }
    newGoods[goods.length] = {
      id: goods.length,
      quantity: 1,
      comment: "",
      product: "",
      price: goods.price ? goods.price : 0,
    };
    await setTimeout(() => {
      setGoods(newGoods);
    }, 100);
  }

  const Validation = () => {
    const empls = document.querySelectorAll("#employes");
    if (POSTs.workshop !== "Выбрать") {
      if (POSTs.employee !== "Выбрать" && empls[0].textContent !== "Выбрать") {
        const day =
          document.getElementById("data_day").value == 0
            ? days
            : document.getElementById("data_day").value;
        const mon =
          document.getElementById("data_month").value == 0
            ? months
            : document.getElementById("data_month").value;
        const dat =
          document.getElementById("data_year").value == 0
            ? years
            : +years + +document.getElementById("data_year").value - 1;
        const d1 = new Date(dat, mon == 0 ? mon : mon - 1, day);
        const d2 = new Date(years, months - 1, toDay.getDate());
        if (d1.getTime() >= d2.getTime()) {
          let prov1 = [];
          empls.forEach((item, index) => {
            prov1[index] = item.textContent;
          });
          let statusGoods = true;
          const goodsValidation = [...new Set(goods.map((el) => el.product))];
          goodsValidation.map(
            (el) =>
              goods.filter((item) => item.product === el).length > 1 &&
              (statusGoods = false)
          );
          let ProductStatus = true;
          let productAvailability = true;
          goods.map((el) => (!!el.product ? true : (ProductStatus = false)));
          // console.log("prov1",prov1, "pr1", pr1)
          goods.map((el) => {
            let newProductAvailability = false;
            products.map((item) => {
              if (el.product == item.data) newProductAvailability = true;
            });
            if (!newProductAvailability) productAvailability = false;
          });
          if (ProductStatus && statusGoods && productAvailability) {
            setIsLoadModal(true);
          } else {
            setAlertText("Ошибка, не все продукты удовлетворяют условию");
            setShow_on(true);
            setTimeout(() => {
              setShow_on(false);
              setTimeout(
                document
                  .getElementById("goods1")
                  .scrollIntoView({ block: "center", behavior: "smooth" }),
                500
              );
            }, 1000);
          }
        } else {
          setAlertText("Не корректный ввод даты доставки");
          setShow_on(true);
          setTimeout(() => {
            setShow_on(false);
            setTimeout(
              document
                .getElementById("data_day")
                .scrollIntoView({ block: "center", behavior: "smooth" }),
              500
            );
          }, 1000);
        }
      } else {
        setAlertText("Выберите имя заказчика");
        setShow_on(true);
        setTimeout(() => {
          setShow_on(false);
          setTimeout(
            document
              .getElementById("employee")
              .scrollIntoView({ block: "center", behavior: "smooth" }),
            500
          );
        }, 2000);
      }
    } else {
      setAlertText("Выберите цех для заказа");
      setShow_on(true);
      setTimeout(() => {
        setShow_on(false);
        setTimeout(
          document
            .getElementById("workshop")
            .scrollIntoView({ block: "center", behavior: "smooth" }),
          500
        );
      }, 2000);
    }
  };

  const value = {
    otherGood,
    dispatch_OtherGood,
    tops,
    AlertText,
    show_on,
    selectRefText,
    workshop,
    isloadWorkshop,
    employee,
    dayAll,
    days,
    MONTH_ALL,
    products,
    monthString,
    YEARS_ALL,
    years,
    goods,
    months,
    setTops,
    setPOSTs,
    employeeName,
    addProdGoods,
    setselectRefText,
    addComments,
    monthSelect,
    addGoodsCount,
    removeGoodsCount,
    AddGoods,
    Validation,
    activeWorkshop,
    UPLOAD_LOGIN,
    dispatch_ActiveWorkshop,
    setInputKey,
    inputKey,
    data_all,
    POSTs,
    isLoadModal,
    setIsLoadModal,
    KEYS,
    setKEYS,
    setAlertText,
    setShow_on,
    Win_width,
  };
  return (
    <CostomContext.Provider value={value}>
      {props.children}
    </CostomContext.Provider>
  );
};
