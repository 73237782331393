import React from "react";
import Midle from "./midle/midle";
import Header from "./header/header.jsx";
import "./_context.scss";
import "./_inputs.scss";
import "./_selectric.scss";
import "./_buttons.scss";
import "./_breadcrambs.scss";
import "./_checkbox.scss";

export default function Content() {
  return (
    <>
      <Header />
      <Midle />
    </>
  );
}
