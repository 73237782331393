import "./App.css";
import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Content from "./Content/content";
import AdminPanel from "./modules/admin/admin";
import { CostomContext } from "./context";
import { Login } from "./login/_login.jsx";
import { OrderSuplly } from "./Supply/_order";
import { OrderDelivery } from "./Delivery/_delivery";

import { Context } from "./Context/_supplyContext";
import {  AdminData } from "./modules/Context/loginAdminContext";

function App() {
  const { KEYS } = useContext(CostomContext);
  return (
    <Router>
      <Switch>
        {/* <Redirect from="/" to="/orders" /> */}
        <Route exact path="/">
          {KEYS ? <Content /> : <Login />}
        </Route>
        <Route path="/admin">
          <AdminData>
            <AdminPanel />
          </AdminData>
        </Route>
        <Context>
          <Route path="/supply/">
            <OrderSuplly />
          </Route>
          <Route path="/delivery/">
            <OrderDelivery />
          </Route>
        </Context>
      </Switch>
    </Router>
  );
}

export default App;
