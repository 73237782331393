import React, { useEffect, useState, useRef } from "react";
import "./_header.scss";
import "./checkbox.scss";

export default function Header({
  adminPanel,
  setActiveNavigation,
  activeNavigation,
}) {
  const [Win_width, setWin_width] = useState(
    document.documentElement.clientWidth
  );
  const [shutters, setShutters] = useState("");
  window.addEventListener("resize", () =>
    setWin_width(document.documentElement.clientWidth)
  );
  useEffect(() => {
    setShutters("");
    if (window.pageYOffset >= 16) {
      setShutters("header--shut");
    } else setShutters("");
    window.onscroll = () => {
      if (window.pageYOffset >= 16) {
        setShutters("header--shut");
      } else setShutters("");
    };
  }, [Win_width]);

  // useEffect(() => {
  //   inputMenu.current.checked = activeNavigation;
  // }, [activeNavigation]);

  return (
    <>
      <div
        onClick={() => console.log(Win_width)}
        className={shutters ? shutters + " header" : " header"}
      >
        <div className="header--inner">
          <a href="https://lukino.ru/">
            <img
              className="header--img"
              src="https://orders.lukino.ru/logowhite.png"
              alt="a"
            />
          </a>
        </div>
      </div>
      <div className="header--nav">
        {!adminPanel && (
          <div className="header--breadcrumbs">
            <ul className="breadcrumbs">
              <li className="breadcrumbs__item">
                <a href="https://lukino.ru/" title="Главная">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlnsa="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
                    x="0px"
                    y="0px"
                    width="1rem"
                    height="0.8rem"
                    viewBox="0 0 228.6 187.5"
                    style={{ enableBackground: "new 0 0 228.6 187.5" }}
                    xmlSpace="preserve"
                  >
                    <defs></defs>
                    <path
                      d="M174.7,163.5h-26v-62.1H79.9v62.1h-26V77.4l60.4-47l60.4,47V163.5z M103.9,125.5h20.8v34.4h-20.8V125.5z M228.6,89L114.3,0
                                L65.8,37.8v-20h-24v38.7L0,89l14.7,18.9l15.2-11.8v91.4h168.8V96.1l15.2,11.8L228.6,89z"
                    />
                  </svg>
                </a>
              </li>
              <li className="breadcrumbs__item">
                <a href="https://orders.lukino.ru/">Заказ продуктов на ферму</a>
              </li>
            </ul>
          </div>
        )}
        {!adminPanel && <div className="label">Заказ продуктов на ферму</div>}
        {adminPanel && (
          <div
            className="label admin__panel"
            data-title="Открыть панель навигации"
          >
            <span onClick={() => setActiveNavigation(!activeNavigation)}>
              Панель Администратора{" "}
            </span>

            <label class="checkbox-ios">
              <input
                // ref={inputMenu}
                checked={activeNavigation}
                type="checkbox"
                className={!activeNavigation ? "" : "focused"}
                onChange={(e) => {
                  setActiveNavigation(e.target.checked);
                }}
              />
              <span class="checkbox-ios-switch"></span>
            </label>
          </div>
        )}
      </div>
    </>
  );
}
