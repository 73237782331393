import React from "react";

export default function CheckboxFilters({ data, setData, name, dataName }) {
  return (
    <div className="orders--filter--checkbox">
      <input
        type="checkbox"
        name={dataName}
        id={dataName}
        checked={data}
        onChange={(e) => {
          setData(e.target.checked);
        }}
      />
      <label htmlFor={dataName}>{name}</label>
    </div>
  );
}
