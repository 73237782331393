import React, { useRef, useEffect } from "react";
import LinksDropdown from "../Dropdown/selected.jsx";
import ProductInput from "../product--Input/productInput.jsx";

export default function Goods(props) {
  const {
    products,
    quantity,
    id,
    addGoodsCount = Function.prototype,
    removeGoodsCount = Function.prototype,
    addComments = Function.prototype,
    addProdGoods = Function.prototype,
  } = props;

  const countRef = useRef();
  const commRef = useRef();

  useEffect(() => {
    countRef.current.value = +quantity.toFixed(1);
  }, [quantity]);

  return (
    <>
      <div className="order__info__item">
        <ProductInput
          ProdId={id}
          data={products}
          id="goods"
          name="goods"
          addProdGoods={addProdGoods}
          key={"links" + id}
        />
        <div className="info--count">
          <div className="order__info__item__count">
            <input
              ref={countRef}
              type="text"
              className="input"
              placeholder="1"
              id="CountGoodsOrder"
              name="CountGoodsOrder"
              // value={quantity}
              onBlur={() => {
                addGoodsCount(id, +countRef.current.value);
              }}
            />
          </div>
          <div style={{ position: "relative", display: "block" }}>
            <div onClick={() => addGoodsCount(id)} className="item__add">
              &#8249;
            </div>
            <div
              className="item__remove"
              onClick={() => removeGoodsCount(id, quantity)}
            >
              &#8249;
            </div>
          </div>
        </div>
      </div>
      <div className="order__info_comment">
        <label className="label" htmlFor="inputKomProduct">
          Комментарий к товару
        </label>
        <div>
          <input
            ref={commRef}
            onChange={() => addComments(id, commRef.current.value)}
            id="kommentGoods"
            name="kommentGoods"
            type="text"
            className="input"
            placeholder="..."
          />
        </div>
      </div>
    </>
  );
}
