import React, { useState, useContext, useEffect } from "react";
import "./filters.scss";
import { useHistory, useLocation } from "react-router-dom";
import { OrdersContext } from "../ordersContext";
import FilterIcon from "../img";
import CheckboxFilters from "./checkboxFilters";
import queryString from "query-string";

export default function OrderFilters(params) {
  const { search, setSearch, data, setData, setLocation, workshopElement } =
    useContext(OrdersContext);
  const searchLoc = useLocation().search;
  const query = queryString.parse(searchLoc);

  const [active, setActive] = useState(false);
  const [animActive, setAnimActive] = useState(false);
  const [date, setDate] = useState(
    query.dateBy || query.date || query.dateFrom ? true : false
  );
  const [completedOrders, setCompletedOrders] = useState(
    query.completedOrders ? query.completedOrders : false
  );
  const [outstandingOrders, setOutstandingOrders] = useState(
    query.outstandingOrders ? query.outstandingOrders : false
  );
  const [confirmedOrders, setConfirmedOrders] = useState(
    query.confirmedOrders ? query.confirmedOrders : false
  );
  const [unconfirmedOrders, setUnconfirmedOrders] = useState(
    query.unconfirmedOrders ? query.unconfirmedOrders : false
  );
  const [period, setPeriod] = useState(
    query.dateBy || query.dateFrom ? true : false
  );
  const [dateFrom, setDateFrom] = useState(
    query.dateFrom ? query.dateFrom : ""
  );
  const [dateBy, setDateBy] = useState(query.dateBy ? query.dateBy : "");
  const [dateNow, setDateNow] = useState(query.date ? query.date : "");
  const [specificDate, setSpecificDate] = useState(
    query.date ? true : false
  );
  const [activeWorkshop, setActiveWorkshop] = useState(false);
  const [workshop, setWorkshop] = useState(
    query.workshop ? query.workshop.split(",").filter((el) => !!el) : []
  );

  const reset = () => {
    setDate(false);
    setCompletedOrders(false);
    setOutstandingOrders(false);
    setConfirmedOrders(false);
    setUnconfirmedOrders(false);
    setPeriod(false);
    setSpecificDate(false);
    setActiveWorkshop(false);
    setWorkshop([]);
  };
  useEffect(() => {
    let newSearch = "";
    if (data && (specificDate || period)) {
      if (specificDate) newSearch += `&date=${dateNow}`;
      if (period) newSearch += `&dateFrom=${dateFrom}&dateBy=${dateBy}`;
    }
    if (outstandingOrders) {
      newSearch = newSearch + `&outstandingOrders=true`;
    }
    if (completedOrders) {
      newSearch = newSearch + `&completedOrders=true`;
    }
    if (confirmedOrders) {
      newSearch = newSearch + `&confirmedOrders=true`;
    }
    if (unconfirmedOrders) {
      newSearch = newSearch + `&unconfirmedOrders=true`;
    }
    if (workshop.length > 0) {
      let newworkshopItem = "";
      workshop.map((el) => (newworkshopItem += el + ","));
      newSearch += `&workshop=${newworkshopItem}`;
    }
    setSearch(newSearch);
  }, [
    completedOrders,
    confirmedOrders,
    data,
    dateBy,
    dateFrom,
    dateNow,
    outstandingOrders,
    period,
    specificDate,
    unconfirmedOrders,
    workshop,
  ]);

  const workshopAdd = (e) => {
    for (let i = 0; i < workshopElement.length; i++) {
      if (e.target.value === workshopElement[i]) {
        setWorkshop([...workshop, e.target.value]);
        return (e.target.value = "");
      }
    }
  };

  useEffect(() => {
    if (specificDate) {
      setPeriod(false);
    }
  }, [specificDate]);

  useEffect(() => {
    if (completedOrders) setOutstandingOrders(false);
  }, [completedOrders]);

  useEffect(() => {
    if (outstandingOrders) setCompletedOrders(false);
  }, [outstandingOrders]);

  useEffect(() => {
    if (confirmedOrders) setUnconfirmedOrders(false);
  }, [confirmedOrders]);

  useEffect(() => {
    if (unconfirmedOrders) setConfirmedOrders(false);
  }, [unconfirmedOrders]);

  useEffect(() => {
    if (period) {
      setSpecificDate(false);
    }
  }, [period]);

  const history = useHistory();

  const loadTable = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(!active);
    setData([]);
    const addressHistory = search ? "?" + search : "/admin/orders/";
    history.push(addressHistory);
    return setLocation(search);
  };

  return (
    <>
      <div onClick={() => setActive(!active)} className="orders--filter--icon">
        <FilterIcon />
      </div>
      {active && (
        <div className="orders--filter--wrapper ">
          <form
            className="orders--filter--body--inner checkbox"
            onSubmit={loadTable}
          >
            <div className="modal--wrapper">
              <div className="table--modal--close" onClick={() => reset()}>
                Сбросить фильтр
              </div>
              <div
                className="table--modal--close"
                onClick={() => setActive(!active)}
              >
                Закрыть
              </div>
            </div>
            <div className="orders--filter--body">
              <CheckboxFilters
                data={date}
                setData={setDate}
                name="Дата"
                dataName="ordersFilterDate"
              />
              {date && (
                <div className="orders--filter--date">
                  <CheckboxFilters
                    data={specificDate}
                    setData={setSpecificDate}
                    name="Конкретная дата"
                    dataName="specificDate"
                  />
                  {specificDate && (
                    <div style={{ width: "80%" }}>
                      <input
                        type="date"
                        className="input workshopFilter--search"
                        value={dateNow}
                        onChange={(e) => setDateNow(e.target.value)}
                        placeholder="01.01.2021"
                        name="specificDate"
                        id="specificDate"
                        pattern="dd-mm-yyyy"
                        required
                      />
                    </div>
                  )}
                  <CheckboxFilters
                    data={period}
                    setData={setPeriod}
                    name="По периоду"
                    dataName="period"
                  />
                  {period && (
                    <div className="workshopFilter--period">
                      <div style={{ width: "80%" }}>
                        <label htmlFor="dateFromFilter">Начальная дата</label>
                        <input
                          id="dateFromFilter"
                          type="date"
                          value={dateFrom}
                          className="input workshopFilter--search"
                          onChange={(e) => setDateFrom(e.target.value)}
                          placeholder="01.01.2021"
                          name="dateFromFilter"
                          pattern="dd-mm-yyyy"
                          required
                        />
                      </div>
                      <div style={{ width: "80%" }}>
                        <label htmlFor="dateByFilter">Конечная дата</label>
                        <input
                          id="dateByFilter"
                          type="date"
                          value={dateBy}
                          className="input workshopFilter--search"
                          min={dateFrom}
                          onChange={(e) => setDateBy(e.target.value)}
                          placeholder="01.01.2021"
                          name={"dateByFilter"}
                          pattern="dd-mm-yyyy"
                          required
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              <CheckboxFilters
                data={completedOrders}
                setData={setCompletedOrders}
                name="Выполненные заказы"
                dataName="completedOrders"
              />
              <CheckboxFilters
                data={outstandingOrders}
                setData={setOutstandingOrders}
                name="Невыполненные заказы"
                dataName="outstandingOrders"
              />
              <CheckboxFilters
                data={confirmedOrders}
                setData={setConfirmedOrders}
                name="Подтвержденные заказы"
                dataName="confirmedOrders"
              />
              <CheckboxFilters
                data={unconfirmedOrders}
                setData={setUnconfirmedOrders}
                name="Неподтвержденные заказы"
                dataName="unconfirmedOrders"
              />
              <div>
                <div className="filter--workshopItems">
                  {workshop.map((el, index) => (
                    <span
                      className="filter--workshopItem"
                      onClick={() =>
                        setWorkshop(workshop.filter((el, id) => index != id))
                      }
                    >
                      {el}
                      <span>x</span>
                    </span>
                  ))}
                </div>
                <div className="settings--inputElement filter--inputElement">
                  <label className="label" htmlFor="workshopFilter">
                    Выберите цех
                  </label>
                  <input
                    type="text"
                    className="input workshopFilter--search"
                    placeholder="Выберите цех"
                    name="workshopFilter"
                    list="workshopFilters"
                    id="workshopFilter"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        workshopAdd(e);
                      }
                    }}
                    onBlur={(e) => {
                      if (!!e.target.value) {
                        workshopAdd(e);
                      }
                    }}
                    onChange={(e) =>
                      workshopElement.map((el) => {
                        if (el === e.target.value) {
                          workshopAdd(e);
                        }
                      })
                    }
                  />
                  <datalist id="workshopFilters">
                    <select name="selectWorkshop">
                      {workshopElement.length > 0 &&
                        workshopElement.map((el, index) => (
                          <option
                            onClick={() => {
                              setWorkshop([...workshop, el]);
                            }}
                            id={"op" + index}
                            value={el}
                          />
                        ))}
                    </select>
                  </datalist>
                </div>
              </div>
            </div>
            <div className="orders--filter--footer">
              {/* <Link to={search ? "?" + search : "/admin/orders/"}> */}
              <button type="submit" className="btn btn-primary orders-button">
                <span>Открыть</span>
              </button>
              {/* </Link> */}
            </div>
          </form>
        </div>
      )}
    </>
  );
}
