import React, { useEffect, useState, useContext } from "react";
import { OrdersContext } from "../ordersContext";
import { useLocation } from "react-router-dom";
import ProductsOrders from "./products";
import OrderTable from "./orderTable";

function OrderManagement(props) {
  const { loadDataOrderItem, dataItem, OrdersEdit } = useContext(OrdersContext);
  const location = useLocation();
  const id = location.pathname;
  useEffect(() => {
    loadDataOrderItem(id);
  }, []);
  const [tableActive, setTableActive] = useState(false);
  return (
    <>
      {tableActive && (
        <OrderTable setTableActive={setTableActive} tableActive={tableActive} />
      )}
      {dataItem && (
        <div className="statistics--workshop orders--management--wrapper">
          <div>
            <span className="orders--management--designation">Цех:</span>{" "}
            {dataItem.workshop}
          </div>
          <div>
            <span className="orders--management--designation">
              Имя заказчика:
            </span>{" "}
            {dataItem.employee}
          </div>
          <div>
            <span className="orders--management--designation">
              Дата на доставку заказа:
            </span>{" "}
            {dataItem.orderDate}
          </div>
          <div>
            <span className="orders--management--designation">
              Товары:{" "}
              <button
                className="btn btn-secondary orders--button"
                onClick={() => setTableActive(!tableActive)}
              >
                <span>Таблица</span>
              </button>
            </span>
            <div className="orders--management--product">
              {dataItem.product.map((el, index) => (
                <ProductsOrders data={el} key={"product-" + index} id={index} />
              ))}
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary orders-button"
            >
              <span>Сохранить изменения</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default React.memo(OrderManagement);
