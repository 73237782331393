import "./_alert.scss"

export default function Alert  ({showon, Text, tops}) {
    return(
        <div className="alert">
        { showon && 
            <div 
            style={{top: `${tops}`}}
            className="alert-show">{Text ? Text : 
            `Спасибо за ваш заказ! \n Данные уже отправлены!`}</div> }
        </div>
    )
}