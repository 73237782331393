import React, { useEffect, useState, useContext } from "react";
import "./orders.scss";
import { OrdersContext } from "./ordersContext";
import { Link, useParams, useLocation } from "react-router-dom";
import OrderFilters from "./filters/filters.jsx";

export default function Orders() {
  const {
    data,
    fetching,
    setFetching,
    loadDataOrders,
    totalCount,
  } = useContext(OrdersContext);


  useEffect(() => {
    if (fetching) loadDataOrders();
  }, [fetching]);

  useEffect(() => {
    document.addEventListener("scroll", ScrollHandler);
    return function () {
      document.removeEventListener("scroll", ScrollHandler);
    };
  }, [totalCount]);

  const ScrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      data.length < totalCount
    ) {
      setFetching(true);
    }
  };
  return (
    <>
      <OrderFilters />
      {data.map((el) => (
        <div className="admin--orders">
          <div
            className={
              el.delivered
                ? "orders--indicator orders--indicator--green"
                : "orders--indicator orders--indicator--red"
            }
          >
            {""}
          </div>
          <div className="orders-label">
            <div>{`От ${el.orderDate} ${el.workshop} на ${el.deliveryDate} на Сумму: ${el.preliminaryAmount}`}</div>
            <div>
              <Link to={"/admin/orders/" + el.id}>
                <button type="submit" className="btn btn-primary orders-button">
                  <span>Открыть</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
