import { React, useContext, useEffect } from "react";
import { AdminContext } from "../../../../Context/loginAdminContext.js";
import "./controlSettings.scss";
import { CostomContext } from "../../../../../context.js";

export default function ControlSettings() {
  const { setCostGoost, costGoost, controlSettings } = useContext(AdminContext);
  const { setAlertText, setShow_on } = useContext(CostomContext);
  useEffect(() => {
    controlSettings();
  }, []);
  const updateControlSettings = () => {
    fetch("https://orders.lukino.ru/api/admin/update", {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      method: "PUT",
      body: JSON.stringify({
        active: costGoost,
      }),
    })
      .then((result) => {
        if (result.ok) {
          setShow_on(true);
          setAlertText("Изменения успешно внесены");
          setTimeout(() => {
            setShow_on(false);
          }, 1700);
        } else {
          setAlertText("Ошибка при отправке данных, повторите запрос!");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="control__settings">
        <div className="control__settings--lable">
          Настройки данных по товарам:
        </div>
        <div className="control__settings--checkbox">
          <div className="checkbox">
            <div>
              <input
                type="checkbox"
                name="costGoost"
                id="costGoost"
                checked={costGoost}
                onChange={(e) => {
                  setCostGoost(e.target.checked);
                }}
              />
              <label htmlFor="costGoost">
                Cтоимость товаров в телеграмме
                <span
                  className={
                    costGoost
                      ? "costGoost--label--active"
                      : "costGoost--label--inactive"
                  }
                >
                  {costGoost ? " включена" : " отключена"}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className="control__settings--save">
          <button
            className="btn btn-primary"
            onClick={() => updateControlSettings()}
          >
            <span>Сохранить</span>
          </button>
        </div>
      </div>
    </>
  );
}
