import { React, useContext } from "react";
import { AdminContext } from "../Context/loginAdminContext";
import { AuthorizationAdmin } from "./modules/authorization/authorization";
import TitleAdmin from "./modules/titel/title";
import { CostomContext } from "../../context.js";
import Alert from "../../Content/alert/_alert.jsx";

export default function AdminPanel() {
  const { tops, show_on, AlertText } =
    useContext(CostomContext);
  const { ADMIN_KEYS } = useContext(AdminContext);
  return (
    <>
      <Alert showon={show_on} Text={AlertText} tops={tops} />
      {!ADMIN_KEYS ? <AuthorizationAdmin /> : <TitleAdmin />}
    </>
  );
}
