function CloseIcon() {
  return (
    <svg id="Flat" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path d="M229.15625,163.8125a6.00012,6.00012,0,1,1-10.39258,6l-19.45459-33.69531A121.90878,121.90878,0,0,1,161.07288,153.79l6.1322,34.77441a6.00077,6.00077,0,0,1-4.86719,6.95117,6.09807,6.09807,0,0,1-1.04883.0918,6.002,6.002,0,0,1-5.90234-4.959L149.32861,156.294a134.20549,134.20549,0,0,1-42.74682-.01368l-6.05738,34.35547a6.002,6.002,0,0,1-5.90234,4.959,6.09915,6.09915,0,0,1-1.04883-.0918,6.00077,6.00077,0,0,1-4.86719-6.95117l6.13306-34.78467a121.90305,121.90305,0,0,1-38.1914-17.68018L37.085,169.9707a6.00012,6.00012,0,0,1-10.39258-6l20.33618-35.22314a145.19157,145.19157,0,0,1-19.69653-20.102,5.9998,5.9998,0,0,1,9.334-7.541,130.85353,130.85353,0,0,0,21.582,21.25586c.12158.085.23645.17578.34985.26856A111.50451,111.50451,0,0,0,128,146a111.50161,111.50161,0,0,0,69.38867-23.36182c.10523-.08447.21094-.16845.32288-.24658A130.83245,130.83245,0,0,0,219.333,101.10449a5.9998,5.9998,0,1,1,9.334,7.541,145.15186,145.15186,0,0,1-19.73681,20.13525Z" />
    </svg>
  );
}
function OpenIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5rem"
      height="1.5rem"
      focusable="false"
      viewBox="0 0 12 12"
    >
      <g fill="none" stroke="currentColor">
        <circle cx="6" cy="6" r="1.5" />
        <path
          stroke-linejoin="round"
          d="M11.38 6.37c.16-.22.16-.52 0-.75-.63-.9-2.5-3.12-5.38-3.12S1.25 4.72.62 5.63c-.16.22-.16.52 0 .75.63.9 2.5 3.12 5.38 3.12s4.75-2.22 5.38-3.13z"
        />
      </g>
    </svg>
  );
}
export { OpenIcon, CloseIcon };
