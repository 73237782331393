import React, { useState, useEffect, useRef, useContext } from "react";
import "./_selected.scss";

export default function LinksDropdown(props) {
  const {
    data = [],
    now,
    widths,
    ProdId,
    isloadWorkshop,
    dispatch_ActiveWorkshop,
    UPLOAD_LOGIN,
    monthSelect = Function.prototype,
    employeeName = Function.prototype,
    id,
    name,
    Pos = Function.prototype,
    addProdGoods = Function.prototype,
    plus,
    lables,
  } = props;

  const [Win_width, setWin_width] = useState(
    document.documentElement.clientWidth
  );
  window.addEventListener("resize", () =>
    setWin_width(document.documentElement.clientWidth)
  );

  const Select = useRef();
  const SelectText = useRef();
  const [isOpenSelect, setIsOpenSelect] = useState(false);

  useEffect(() => {
    SelectText.current.innerText =
      Select.current.options[Select.current.value].text;
    employeeName(Select.current.options[Select.current.value].value);
    addProdGoods(ProdId, Select.current.options[Select.current.value].text);
    Pos();
  }, [isOpenSelect, now]);

  return (
    <div
      tabIndex="-1"
      key={now}
      onBlur={(e) => {
        if (Win_width > 540) setIsOpenSelect(false);
      }}
      style={{ width: widths ? { widths, marginLeft: "0" } : "15rem" }}
      onClick={(e) => {
        if (Win_width > 540) setIsOpenSelect(!isOpenSelect);
        if (Win_width <= 540) {
          Select.current.focus();
          Select.current.size = Select.current.lenght;
        }
      }}
      className={
        isOpenSelect
          ? " selectric-wrapper selectric-js-select-time selectric-belowselectric-open selectric-focus "
          : "selectric-wrapper selectric-js-select-time selectric-below"
      }
    >
      <div
        className={
          Win_width <= 540
            ? "selectric-is-native selectric-hide-select"
            : "selectric-hide-select"
        }
      >
        <select
          tabIndex="0"
          ref={Select}
          onChange={(e) => {
            if (plus) {
              SelectText.current.innerText =
                Select.current.options[
                  +Select.current.value === 0
                    ? Select.current.value
                    : +Select.current.value + +plus
                ].text;
            } else {
              SelectText.current.innerText =
                Select.current.options[Select.current.value].text;
            }
            monthSelect(
              Select.current.options[Select.current.value].dataset.index
            );
            employeeName(
              Select.current.options[Select.current.value].value,
              true
            );
            Pos();
            UPLOAD_LOGIN &&
              dispatch_ActiveWorkshop({
                type: UPLOAD_LOGIN,
                data: Select.current.options[Select.current.value].text,
              });
            addProdGoods(
              ProdId,
              Select.current.options[Select.current.value].text
            );
          }}
          className="js-select-time"
          id={id}
          name={name}
        >
          <option data-index="0" key={"op"} value="0">
            {now ? now : "Выбрать"}
          </option>
          {data.map((item, index) => (
            <>
              {item.type == "day" ? (
                index != 0 && (
                  <option
                    data-index={index + 1}
                    key={"opt" + index}
                    value={item.id}
                  >
                    {+item.data - 1}
                  </option>
                )
              ) : (
                <option
                  data-index={index + 1}
                  key={"opt" + index}
                  value={item.id}
                >
                  {item.data}
                </option>
              )}
            </>
          ))}
        </select>
      </div>
      <div className="selectric">
        <span
          id={lables}
          onBlur={() =>
            addProdGoods(
              ProdId,
              Select.current.options[Select.current.value].text
            )
          }
          ref={SelectText}
          className="label"
        ></span>
        <b className="button">▾</b>
      </div>

      {isOpenSelect && (
        <div
          className="selectric-items"
          style={{ display: "block", zIndex: "29", width: "100%" }}
        >
          <div className="selectric-scroll" style={{ height: "3rem" }}>
            <ul>
              <li
                key={"l"}
                className="highlighted"
                data-index="0"
                onClick={(e) => {
                  setIsOpenSelect(!isOpenSelect);
                  Select.current.options[
                    e.target.dataset.index
                  ].selected = true;
                }}
              >
                {now ? now : "Выбрать"}
              </li>
              {!isloadWorkshop &&
                data.map((item, index, array) => (
                  <>
                        {item.type === "day" ? (
                      (index !== array.length - 1 ) && (
                          
                        <li
                          key={"li" + index}
                          data-index={index + 1}
                          onClick={(e) => {
                            setIsOpenSelect(!isOpenSelect);
                            Select.current.options[
                              e.target.dataset.index
                            ].selected = true;
                            monthSelect(e.target.dataset.index);
                            addProdGoods(ProdId, item.data);
                            UPLOAD_LOGIN &&
                              dispatch_ActiveWorkshop({
                                type: UPLOAD_LOGIN,
                                data: item.data,
                              });
                            employeeName(
                              Select.current.options[Select.current.value]
                                .value,
                              true
                            );
                          }}
                        >
                          {item.data}
                        </li>
                      )
                    ) : (
                      <li
                        key={"li" + index}
                        data-index={index + 1}
                        onClick={(e) => {
                          setIsOpenSelect(!isOpenSelect);
                          Select.current.options[
                            e.target.dataset.index
                          ].selected = true;
                          monthSelect(e.target.dataset.index);
                          addProdGoods(ProdId, item.data);
                          UPLOAD_LOGIN &&
                            dispatch_ActiveWorkshop({
                              type: UPLOAD_LOGIN,
                              data: item.data,
                            });
                          employeeName(
                            Select.current.options[Select.current.value].value,
                            true
                          );
                        }}
                      >
                        {item.data}
                      </li>
                    )}
                  </>
                ))}
            </ul>
          </div>
        </div>
      )}
      <input className="selectric-input" tabIndex="1" />
    </div>
  );
}
